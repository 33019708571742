import { Grid, Typography } from "@material-ui/core";
import _ from "lodash";
import { useRouteMatch } from "react-router-dom";
import React, { useState } from "react";

import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
} from "../../../../utils/consts";
import WithLoading from "../../../../utils/WithLoading";
import StrategyInfoNode from "./StrategyInfoNode";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import StrategyAddModal from "./StrategyAddModal";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import AlertDialogDefault from "../../../../components/alerts/AlertDialogDefault";
import { saveLogs } from "../../../../utils/firebase/logs";
import {
  createNewRewardsInfo,
  createNewStrategyInfo,
  updateRewardsInfo,
  updateStrategyInfo,
  updateStrategyStatus,
} from "../../../../services/customerApp/customerDetails";
import StrategyEditModal from "./StratrgyEditModal";
import RewardsAddModal from "./RewardsAddModal";

export interface StrategyPageLoadingProps {
  nodes: Array<any>;
  isLoading: boolean;
  setError: any;
  getStrategyInfo: any;
  setStrategyNodeList: any;
  isActive: any;
  isOwner: any;
}

/**
 * Component: StrategyPageLoading
 *
 * This component represents the loading state of the menu strategies page.
 * It handles the rendering of menu strategy nodes, including their creation, editing, duplication, activation, and deactivation.
 */
const StrategyPageLoading: React.FunctionComponent<
  StrategyPageLoadingProps
> = ({
  nodes,
  isLoading,
  setError,
  getStrategyInfo,
  setStrategyNodeList,
  isActive,
  isOwner,
}) => {
  const [isOpenStrategyAddModal, setIsOpenStrategyAddModal] = useState(false);
  const [isOpenStrategyEditModal, setIsOpenStrategyEditModal] = useState(false);
  const [strategyName, setStrategyName] = useState("");
  const [strategyNameInitial, setStrategyNameInitial] = useState("");
  const [deliveryTypesList, setDeliveryTypesList] = useState<any>([]);
  const [deliveryTypesListInitial, setDeliveryTypesListInitial] = useState<any>(
    [],
  );
  const [availability, setAvailability] = useState<any>({});
  const [availabilityInitial, setAvailabilityInitial] = useState<any>({});
  const [selectedStrategyNode, setSelectedStrategyNode] = useState<any>([]);
  const [isDeliveryTypeEmpty, setIsDeliveryTypeEmpty] = useState(false);
  const [isNameEmpty, setIsNameEmpty] = useState(false);
  const [isAvailabilityEmpty, setIsAvailabilityEmpty] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isLoadingButtonDeactivate, setIsLoadingButtonDeactivate] =
    useState<any>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [id, setId] = useState("");
  const [version, setVersion] = useState(0);
  const [nodeData, setNodeData] = useState<any>([]);
  const [success, setSuccess] = useState("");
  const [isOpenRewardsAddModal, setIsOpenRewardsAddModal] = useState(false);
  const [activeInactiveSelectedList, setActiveInactiveSelectedList] =
    useState<any>([]);
  const [rewardDetails, setRewardDetails] = useState<any>([]);
  const [isPointsReset, setIsPointsReset] = useState(false);

  const match: any = useRouteMatch();

  /**
   * Function: handleReset
   *
   * This function resets all state variables related to discount strategy editing and creation to their initial values.
   */
  const handleReset = () => {
    setIsEdit(false);
    setIsDuplicate(false);
    setIsOpenStrategyAddModal(false);
    setStrategyName("");
    setDeliveryTypesList([]);
    setDeliveryTypesListInitial([]);
    setAvailability({});
    setAvailabilityInitial({});
    setIsLoadingButtonDeactivate([]);
    setIsLoadingButton(false);
    setIsNameEmpty(false);
    setIsDeliveryTypeEmpty(false);
    setNodeData([]);
    setId("");
    setVersion(0);
    setStrategyNameInitial("");
    setIsAvailabilityEmpty(false);
  };

  /**
   * Function: handleOpenAddStrategyModal
   * This function is called to open the modal for adding a new strategy.
   * It initializes the state variables related to the strategy editing modal and sets the availability to an empty array.
   */
  const handleOpenAddStrategyModal = () => {
    setIsEdit(false);
    setIsOpenStrategyAddModal(true);
  };

  /**
   * Function: handleOpenEditModal
   * This function is called to open the modal for editing an existing strategy.
   * It initializes the state variables related to the strategy editing modal with the values from the provided nodeData object.
   */
  const handleOpenEditModal = (nodeData: any) => {
    setIsOpenStrategyEditModal(true);
    setSelectedStrategyNode(nodeData);
  };

  /**
   * Function: handleOpenDuplicateEditModal
   * This function is called to open the modal for duplicating and editing an existing strategy.
   * It initializes the state variables related to the strategy editing modal with the values from the provided nodeData object,
   * with modifications to indicate that it's a duplication operation.
   */
  const handleOpenDuplicateEditModal = (nodeData: any) => {
    setAvailability({ availability: nodeData.availability });
    setAvailabilityInitial({ availability: nodeData.availability });
    setStrategyName(`Copy of ${nodeData.name}`);
    setStrategyNameInitial(nodeData.name);
    setDeliveryTypesList(nodeData.deliveryTypes);
    setDeliveryTypesListInitial(nodeData.deliveryTypes);
    setIsOpenStrategyAddModal(true);
    setId(nodeData.id);
    setVersion(nodeData.version);
  };

  /**
   * Function: handleOpenConfirmationModal
   *
   * This function is called to open the confirmation modal for duplicating a strategy.
   * It sets the state variables to indicate that it's a duplication operation and sets the nodeData object for further processing.
   */
  const handleOpenConfirmationModal = (nodeData: any) => {
    setIsEdit(false);
    setIsDuplicate(true);
    setNodeData(nodeData);
  };

  /**
   * Function: handleCreateNewStrategy
   *
   * This function is called to create a new strategy by making an asynchronous API call to createStrategyInfo.
   * Upon successful creation, it updates the strategy information, resets the form, and displays a success message.
   * If there's an error during the process, it handles the error by setting an error message and resetting the loading state.
   */
  const handleCreateNewStrategy = async (strategyInfo: any) => {
    setIsLoadingButton(true);
    try {
      const res = await createNewStrategyInfo(
        match.params.locationId,
        strategyInfo,
      );

      let summary = [];
      summary.push(`Created cut of price is ${strategyInfo.cut_of_price}`);

      summary.push(
        `Created min order price is ${strategyInfo.min_order_price}`,
      );

      summary.push(`Created price per unit is ${strategyInfo.price_per_unit}`);

      summary.push(`Created unit type is ${strategyInfo.allocate_type}`);

      const summaryString = summary.join("\\n");
      saveLogs(
        {
          message: "Strategy Information Added",
          summary: summaryString,
          type: "info",
          code: "create",
          metadata: { changedBy: "" },
        },
        "customer",
        match.params.locationId,
      );

      const { data } = res;

      const response = data.data;
      const cloneNodes = _.cloneDeep(nodes);
      cloneNodes.push(response);
      setStrategyNodeList(cloneNodes);
      // Set the success message to indicate that the strategy was created successfully.
      setSuccess(SUCCESSFULLY_CREATED);
      setIsOpenStrategyAddModal(false);
      setIsLoadingButton(false);
    } catch (error) {
      // If an error occurs during the creation process, handle the error by setting isLoadingButton to false and
      // displaying an error message.
      setIsLoadingButton(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function: handleUpdateNewStrategy
   *
   * This function handles the update of a new strategy information. It makes an asynchronous API call to updateStrategyInfo with
   * the locationId and strategyInfo parameters. Upon receiving the response, it checks if the status is HttpStatus.CONFLICT_409,
   * indicating a conflict in the update process. If a conflict occurs, it retrieves the latest version of the
   * strategy information using getSingleStrategyInfo and retries the update. If no conflict occurs,
   * it triggers the getStrategyInfo function to refresh the strategy information list, resets the form using handleReset,
   * and sets a success message indicating the successful update. If an error occurs during the process, it sets an error message.
   */
  const handleUpdateStrategy = async (strategyInfo: any) => {
    setIsLoadingButton(true);
    try {
      const res = await updateStrategyInfo(
        match.params.locationId,
        strategyInfo,
      );

      let summary = [];
      if (selectedStrategyNode.name !== strategyInfo.name) {
        summary.push(
          `${strategyNameInitial} strategy name changed from ${selectedStrategyNode.name} to ${strategyInfo.name}`,
        );
      }

      if (
        selectedStrategyNode.min_order_price !== strategyInfo.min_order_price
      ) {
        summary.push(
          `${strategyNameInitial} min order price changed from ${selectedStrategyNode.min_order_price} to ${strategyInfo.min_order_price}`,
        );
      }

      if (selectedStrategyNode.price_per_unit !== strategyInfo.price_per_unit) {
        summary.push(
          `${strategyNameInitial} price per unit changed from ${selectedStrategyNode.price_per_unit} to ${strategyInfo.price_per_unit}`,
        );
      }

      if (selectedStrategyNode.allocate_type !== strategyInfo.allocate_type) {
        summary.push(
          `${strategyNameInitial} unit type changed from ${selectedStrategyNode.allocate_type} to ${strategyInfo.allocate_type}`,
        );
      }

      const summaryString = summary.join("\\n");
      saveLogs(
        {
          message: "Strategy Information Changed",
          summary: summaryString,
          type: "update",
          code: "info",
          metadata: { changedBy: "" },
        },
        "customer",
        match.params.locationId,
      );

      const { data } = res;

      const response = data.data;
      const cloneNodes = _.cloneDeep(nodes);
      cloneNodes.map((node: any) => {
        if (response.strategyId === node.strategyId) {
          node.cutoff_price = response.cutoff_price;
          node.min_order_price = response.min_order_price;
          node.price_per_unit = response.price_per_unit;
          node.allocate_type = response.allocate_type;
          node.name = response.name;
        }
      });
      setStrategyNodeList(cloneNodes);
      setIsLoadingButton(false);
      setIsOpenStrategyEditModal(false);
    } catch (error) {
      // If an error occurs during the process, set the error message to indicate the unexpected error.
      setIsLoadingButton(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function: handleDeactivateStrategy
   *
   * This function handles the deactivation of a strategy.
   * It updates the validity status of the strategy by toggling the 'valid' property.
   * It sets the loading state for the deactivate button associated with the strategy to indicate the deactivation process.
   */
  const handleDeactivateStrategy = async (nodeData: any, valid: any) => {
    // Set loading state for the deactivate button associated with the strategy.
    setIsLoadingButtonDeactivate((prevState: any) => [
      ...prevState,
      nodeData.strategyId,
    ]);

    const strategyInfo = {
      isActive: !valid,
      programId: nodeData.programId,
      strategyId: nodeData.strategyId,
    };

    const res = await updateStrategyStatus(
      match.params.locationId,
      strategyInfo,
    );

    const summaryString = `${
      nodeData.name
    } Status changed from ${!strategyInfo.isActive} to ${
      strategyInfo.isActive
    }`;
    saveLogs(
      {
        message: "Strategy Information Changed",
        summary: summaryString,
        type: "update",
        code: "info",
        metadata: { changedBy: "" },
      },
      "customer",
      match.params.locationId,
    );

    if (res?.data?.data) {
      const cloneNodes = _.cloneDeep(nodes);
      cloneNodes.map((node: any) => {
        node.isActive = res?.data?.data.strategies[node.strategyId];
      });
      const cloneIsLoadingButtonDeactivate = _.cloneDeep(
        isLoadingButtonDeactivate,
      );
      const filter = cloneIsLoadingButtonDeactivate.filter(
        (data: any) => data !== nodeData.strategyId,
      );
      setIsLoadingButtonDeactivate(filter);
      setStrategyNodeList(cloneNodes);
    }
  };

  const handleOpenRewardsAddModal = (nodeData: any) => {
    setSelectedStrategyNode(nodeData);
    setIsOpenRewardsAddModal(true);
  };

  const handleEditRewards = async (formData: any, rewardId: any) => {
    setIsLoadingButton(true);
    try {
      const res = await updateRewardsInfo(
        match.params.locationId,
        rewardId,
        formData,
      );

      let summary = [];
      summary.push(`Created Title is ${formData.title}`);

      formData.description &&
        summary.push(`Created Description is ${formData.description}`);

      formData.img && summary.push(`Created Image is ${formData.img}`);

      formData.units_per_cash &&
        summary.push(`Created Units Per Cash is ${formData.units_per_cash}`);

      const summaryString = summary.join("\\n");
      saveLogs(
        {
          message: "Rewards Information Added",
          summary: summaryString,
          type: "info",
          code: "create",
          metadata: { changedBy: "" },
        },
        "customer",
        match.params.locationId,
      );

      const { data } = res;

      const response = data.data;
      const cloneNodes = _.cloneDeep(nodes);
      cloneNodes.map((node: any) => {
        if (node.strategyId === Object.keys(response)[0]) {
          node["rewards"] = Object.values(response);
        }
      });

      setStrategyNodeList(cloneNodes);
      // Set the success message to indicate that the strategy was created successfully.
      setSuccess(SUCCESSFULLY_CREATED);
      setIsOpenRewardsAddModal(false);
      setIsLoadingButton(false);
    } catch (error) {
      // If an error occurs during the creation process, handle the error by setting isLoadingButton to false and
      // displaying an error message.
      setIsLoadingButton(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleCreateRewards = async (formData: any) => {
    setIsLoadingButton(true);
    try {
      const res = await createNewRewardsInfo(match.params.locationId, formData);
      let summary = [];
      summary.push(`Created Title is ${formData.title}`);

      formData.description &&
        summary.push(`Created Description is ${formData.description}`);

      formData.img && summary.push(`Created Image is ${formData.img}`);

      formData.units_per_cash &&
        summary.push(`Created Units Per Cash is ${formData.units_per_cash}`);

      const summaryString = summary.join("\\n");
      saveLogs(
        {
          message: "Rewards Information Added",
          summary: summaryString,
          type: "info",
          code: "create",
          metadata: { changedBy: "" },
        },
        "customer",
        match.params.locationId,
      );

      const { data } = res;
      const response = data.data;
      const cloneNodes = _.cloneDeep(nodes);
      cloneNodes.map((node: any) => {
        if (node.strategyId === Object.keys(response)[0]) {
          node["rewards"] = Object.values(response);
        }
      });

      setStrategyNodeList(cloneNodes);
      // Set the success message to indicate that the strategy was created successfully.
      setSuccess(SUCCESSFULLY_CREATED);
      setIsOpenRewardsAddModal(false);
      setIsLoadingButton(false);
    } catch (error) {
      // If an error occurs during the creation process, handle the error by setting isLoadingButton to false and
      // displaying an error message.
      setIsLoadingButton(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleCreateNewRewards = async (formData: any) => {
    const rewardsInfo = {
      description: rewardDetails.description,
      img: rewardDetails.img,
      product_id: rewardDetails.product_id,
      strategy_id: rewardDetails.strategy_id,
      title: rewardDetails.title,
      type: "product",
      is_points_reset: isPointsReset,
      units_to_redeem: rewardDetails.units_to_redeem,
      products: formData,
    };
    try {
      const res = await createNewRewardsInfo(
        match.params.locationId,
        rewardsInfo,
      );

      let summary = [];
      summary.push(`Created Title is ${rewardDetails.title}`);

      rewardDetails.description &&
        summary.push(`Created Description is ${rewardDetails.description}`);

      rewardDetails.img &&
        summary.push(`Created Image is ${rewardDetails.img}`);

      rewardDetails.units_per_cash &&
        summary.push(
          `Created Units Per Cash is ${rewardDetails.units_per_cash}`,
        );

      rewardDetails &&
        summary.push(`Created Product details is ${rewardDetails}`);

      const summaryString = summary.join("\\n");
      saveLogs(
        {
          message: "Rewards Information Added",
          summary: summaryString,
          type: "info",
          code: "create",
          metadata: { changedBy: "" },
        },
        "customer",
        match.params.locationId,
      );

      const { data } = res;

      const response = data.data;
      const cloneNodes = _.cloneDeep(nodes);

      cloneNodes.map((node: any) => {
        if (node.strategyId === Object.keys(response)[0]) {
          node["rewards"] = Object.values(response)[0];
        }
      });
      setStrategyNodeList(cloneNodes);
      // Set the success message to indicate that the strategy was created successfully.
      setSuccess(SUCCESSFULLY_CREATED);
      setIsOpenRewardsAddModal(false);
      setIsLoadingButton(false);
    } catch (error) {
      // If an error occurs during the creation process, handle the error by setting isLoadingButton to false and
      // displaying an error message.
      setIsLoadingButton(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleUpdateNewRewards = async (formData: any, rewardId: any) => {
    const rewardsInfo = {
      description: rewardDetails.description,
      img: rewardDetails.img,
      product_id: rewardDetails.product_id,
      strategy_id: rewardDetails.strategy_id,
      title: rewardDetails.title,
      type: "product",
      is_points_reset: isPointsReset,
      units_to_redeem: rewardDetails.units_to_redeem,
      products: formData,
    };

    try {
      const res = await updateRewardsInfo(
        match.params.locationId,
        rewardId,
        rewardsInfo,
      );

      let summary = [];
      summary.push(`Created Title is ${rewardDetails.title}`);

      rewardDetails.description &&
        summary.push(`Created Description is ${rewardDetails.description}`);

      rewardDetails.img &&
        summary.push(`Created Image is ${rewardDetails.img}`);

      rewardDetails.units_per_cash &&
        summary.push(
          `Created Units Per Cash is ${rewardDetails.units_per_cash}`,
        );

      rewardDetails &&
        summary.push(`Created Product details is ${rewardDetails}`);

      const summaryString = summary.join("\\n");
      saveLogs(
        {
          message: "Rewards Information Added",
          summary: summaryString,
          type: "info",
          code: "create",
          metadata: { changedBy: "" },
        },
        "customer",
        match.params.locationId,
      );

      const { data } = res;

      const response = data.data;
      const cloneNodes = _.cloneDeep(nodes);

      cloneNodes.map((node: any) => {
        if (node.strategyId === Object.keys(response)[0]) {
          node["rewards"] = Object.values(response);
        }
      });

      setStrategyNodeList(cloneNodes);
      // Set the success message to indicate that the strategy was created successfully.
      setSuccess(SUCCESSFULLY_CREATED);
      setIsOpenRewardsAddModal(false);
      setIsLoadingButton(false);
    } catch (error) {
      // If an error occurs during the creation process, handle the error by setting isLoadingButton to false and
      // displaying an error message.
      setIsLoadingButton(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleSubmitRewardsTypePoints = async (
    formData: any,
    isEdit: any,
    rewardId?: any,
  ) => {
    setIsLoadingButton(true);
    if (isEdit) {
      handleUpdateNewRewards(formData, rewardId);
    } else {
      handleCreateNewRewards(formData);
    }
  };

  return (
    <>
      {isActive && (
        <Grid container spacing={2} style={{ placeItems: "center" }}>
          {/* <Grid item xs={4} lg={3}>
          <FilterCustom
            openFilterCard={openFilterCard}
            setOpenFilterCard={setOpenFilterCard}
            activeInactiveSelectedList={activeInactiveSelectedList}
            setActiveInactiveSelectedList={setActiveInactiveSelectedList}
          />
        </Grid> */}
          <Grid
            item
            xs={12}
            // xs={8}
            // lg={9}
            style={{ display: "flex", justifyContent: "end" }}
          >
            <ButtonCommon
              style={{
                fontSize: 11,
                width: "160px",
                height: "40px",
              }}
              disabled={!isOwner}
              variant="contained"
              color="green"
              onClick={handleOpenAddStrategyModal}
            >
              Add New Strategy
            </ButtonCommon>
          </Grid>

          {_.isEmpty(nodes) && (
            <Grid container>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Typography variant="h4" style={{ marginTop: "5%" }}>
                  No strategy available.
                </Typography>
              </Grid>
            </Grid>
          )}

          {!_.isEmpty(nodes) &&
            nodes.map((nodeData: any) =>
              _.isEmpty(activeInactiveSelectedList) ||
              activeInactiveSelectedList.length === 2 ? (
                <Grid
                  item
                  xs={12}
                  key={nodeData.id}
                  style={{ display: "flex" }}
                >
                  <StrategyInfoNode
                    nodeData={nodeData}
                    handleOpenEditModal={handleOpenEditModal}
                    handleDeactivateStrategy={handleDeactivateStrategy}
                    isLoadingButtonDeactivate={isLoadingButtonDeactivate}
                    handleOpenConfirmationModal={handleOpenConfirmationModal}
                    handleOpenRewardsAddModal={handleOpenRewardsAddModal}
                    isOwner={isOwner}
                  />
                </Grid>
              ) : activeInactiveSelectedList[0]?.id === "active" ? (
                nodeData.valid && (
                  <Grid
                    item
                    xs={12}
                    key={nodeData.id}
                    style={{ display: "flex" }}
                  >
                    <StrategyInfoNode
                      nodeData={nodeData}
                      handleOpenEditModal={handleOpenEditModal}
                      handleDeactivateStrategy={handleDeactivateStrategy}
                      isLoadingButtonDeactivate={isLoadingButtonDeactivate}
                      handleOpenRewardsAddModal={handleOpenRewardsAddModal}
                      isOwner={isOwner}
                    />
                  </Grid>
                )
              ) : (
                !nodeData.valid && (
                  <Grid
                    item
                    xs={12}
                    key={nodeData.id}
                    style={{ display: "flex" }}
                  >
                    <StrategyInfoNode
                      nodeData={nodeData}
                      handleOpenEditModal={handleOpenEditModal}
                      handleDeactivateStrategy={handleDeactivateStrategy}
                      isLoadingButtonDeactivate={isLoadingButtonDeactivate}
                      handleOpenRewardsAddModal={handleOpenRewardsAddModal}
                      isOwner={isOwner}
                    />
                  </Grid>
                )
              ),
            )}
        </Grid>
      )}
      {isOpenStrategyAddModal && (
        <StrategyAddModal
          isOpen={isOpenStrategyAddModal}
          setIsOpen={setIsOpenStrategyAddModal}
          handleCreateNewStrategy={handleCreateNewStrategy}
          isLoadingButton={isLoadingButton}
          isEdit={isEdit}
          isDuplicate={isDuplicate}
          handleReset={handleReset}
        />
      )}
      {isOpenStrategyEditModal && (
        <StrategyEditModal
          isOpen={isOpenStrategyEditModal}
          setIsOpen={setIsOpenStrategyEditModal}
          handleUpdateStrategy={handleUpdateStrategy}
          isLoadingButton={isLoadingButton}
          isEdit={isEdit}
          isDuplicate={isDuplicate}
          setIsOpenStrategyEditModal={setIsOpenStrategyEditModal}
          selectedStrategyNode={selectedStrategyNode}
        />
      )}

      {isOpenRewardsAddModal && (
        <RewardsAddModal
          isOpen={isOpenRewardsAddModal}
          setIsOpen={setIsOpenRewardsAddModal}
          handleCreateRewards={handleCreateRewards}
          rewardNode={selectedStrategyNode?.rewards}
          selectedStrategyNode={selectedStrategyNode}
          isLoadingPage={isLoadingButton}
          handleSubmitRewardsTypePoints={handleSubmitRewardsTypePoints}
          setRewardDetails={setRewardDetails}
          setIsPointsReset={setIsPointsReset}
          isPointsReset={isPointsReset}
          handleEditRewards={handleEditRewards}
          rewardDetails={rewardDetails}
          setError={setError}
          isLoadingButton={isLoadingButton}
          isOwner={isOwner}
        />
      )}
      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />
      <AlertDialogDefault
        open={isDuplicate}
        confirmAction={() => handleOpenDuplicateEditModal(nodeData)}
        cancelAction={() => setIsDuplicate(false)}
        title="Confirm Printer Mapping Duplication"
        desc="Are you sure you want to duplicate this printer mapping strategy? All associated category and item printer mappings will also be duplicated."
        severity="warning"
        confirmLabel="Confirm Duplicate"
        cancelLabel="cancel"
        color="secondary"
        disableBackdropClick
        isLoading={isLoadingButton}
      />
    </>
  );
};

export default WithLoading(StrategyPageLoading);

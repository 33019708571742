import {
  Grid,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import React from "react";
import _ from "lodash";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import CardCommon from "../../../../components/card/CardCommon";
import { CustomTheme } from "../../../../types/customTheme";

const useStyles = makeStyles((theme: any) => createStyles({}));

export interface CategoryMenuListProps {
  rewardDetails: any;
  handleEditRewardDetails: any;
  setLevel: any;
  setIsEdit: any;
}

/**
 * RewardsDetailsNode Component: Category Menu List
 *
 * This component represents a list item within a category menu. It displays information about an item, including its name,
 * image (if available), and description. Users can interact with the item to view more details or perform actions.
 */
const RewardsDetailsNode: React.FunctionComponent<CategoryMenuListProps> = ({
  rewardDetails,
  handleEditRewardDetails,
}) => {
  const theme: CustomTheme = useTheme();
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <CardCommon backgroundColor={"table_header_background"}>
          <Grid container style={{ minHeight: "40px", placeItems: "center" }}>
            <Grid item xs={3} style={{ paddingLeft: "12px" }}>
              <Typography>Title</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography>Description</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>Units To Redeem</Typography>
            </Grid>
          </Grid>
        </CardCommon>
      </Grid>
      {Object.entries(rewardDetails).map(([key, value]: any) => (
        <Grid item xs={12} style={{ marginTop: "12px" }}>
          <CardCommon key={key} backgroundColor={"entity_background"}>
            <Grid container style={{ minHeight: "48px", placeItems: "center" }}>
              <Grid item xs={3} style={{ paddingLeft: "12px" }}>
                {value.title}
              </Grid>
              <Grid item xs={5}>
                {value.description}
              </Grid>
              <Grid item xs={2}>
                {value.units_to_redeem}
              </Grid>
              <Grid
                item
                xs={2}
                style={{ display: "flex", justifyContent: "end" }}
              >
                <ButtonCommon
                  style={{
                    fontSize: 11,
                    width: "100px",
                    marginRight: "12px",
                  }}
                  variant="contained"
                  color="yellow"
                  onClick={() => handleEditRewardDetails(key, value)}
                >
                  Edit
                </ButtonCommon>
              </Grid>
            </Grid>
          </CardCommon>
        </Grid>
      ))}
    </Grid>
  );
};

export default RewardsDetailsNode;

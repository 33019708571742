import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import _ from "lodash";

import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";
import { useForm } from "react-hook-form";
import PointsModalContent from "./PointsModalContent";
import { useRouteMatch } from "react-router-dom";
import StepLevelOne from "./StepLevelOne";
import StepLevelThree from "./StepLevelThree";

interface FormData {
  title: string;
  description: string;
  img: string;
  units_per_cash: string;
  units_to_redeem?: any;
  isLoadingButton: any;
}

export interface StrategyAddEditModalProps {
  isOpen: any;
  setIsOpen: any;
  handleCreateRewards: any;
  rewardNode: any;
  selectedStrategyNode: any;
  isLoadingPage: any;
  handleSubmitRewardsTypePoints: any;
  setRewardDetails: any;
  setIsPointsReset: any;
  isPointsReset: any;
  handleEditRewards: any;
  setError: any;
  isLoadingButton: any;
  isOwner: any;
  programId: any;
  editableRewardId: any;
  editableRewardDetails: any;
  isEditDetails: any;
  handleSubmitRewardsTypePointsEdit: any;
  setIsEditDetails: any;
  level: any;
  setLevel: any;
  handleSubmitRewardsTypePointsEdits: any;
  isEditPoint: any;
  setIsEditPoint: any;
  isPointsResetFirstObj: any;
}

/**
 * RewardsAddModal Component
 *
 * This component is responsible for rendering a modal dialog for adding, editing, or duplicating a strategy.
 * It includes fields for strategy name, delivery types (Dine In, Delivery, Takeout), and availability.
 * The user can select delivery types using switches and set availability using an availability selection component.
 * Additionally, the component provides options for canceling, saving, or creating the strategy.
 */
const RewardsAddModal: React.FunctionComponent<StrategyAddEditModalProps> = ({
  isOpen,
  setIsOpen,
  handleCreateRewards,
  rewardNode,
  selectedStrategyNode,
  isLoadingPage,
  handleSubmitRewardsTypePoints,
  setRewardDetails,
  setIsPointsReset,
  isPointsReset,
  handleEditRewards,
  setError,
  isLoadingButton,
  isOwner,
  programId,
  editableRewardId,
  editableRewardDetails,
  isEditDetails,
  handleSubmitRewardsTypePointsEdit,
  setIsEditDetails,
  level,
  setLevel,
  isEditPoint,
  handleSubmitRewardsTypePointsEdits,
  setIsEditPoint,
  isPointsResetFirstObj,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isSelectCash, setIsSelectCash] = useState(false);
  const [selectedWrapperIdList, setSelectedWrapperIdList] = useState<any>([]);
  const [selectedWrapperIdListInitial, setSelectedWrapperIdListInitial] =
    useState<any>([]);
  const [isOpenLoadingModal, setIsOpenLoadingModal] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    reset,
    formState: { isDirty },
  } = useForm<FormData>({
    defaultValues: {
      title: "",
      description: "",
      img: "",
      units_per_cash: "",
      units_to_redeem: "",
    },
  });
  const match: any = useRouteMatch();

  useEffect(() => {
    if (editableRewardId && isOpen) {
      setIsEdit(true);
      setIsPointsReset(isPointsResetFirstObj);
      setSelectedWrapperIdList(
        editableRewardDetails?.products ? editableRewardDetails?.products : {},
      );
      reset({
        title: editableRewardDetails?.title,
        description: editableRewardDetails?.description,
        img: editableRewardDetails?.img,
        units_to_redeem: editableRewardDetails?.units_to_redeem,
        units_per_cash: editableRewardDetails?.units_per_cash,
      });
      if (isEditPoint) {
        setLevel(4);
      } else {
        setLevel(3);
      }
      setSelectedWrapperIdListInitial(
        editableRewardDetails?.products ? editableRewardDetails?.products : {},
      );
      setIsSelectCash(editableRewardDetails?.type === "cash");
    } else {
      setIsPointsReset(isPointsResetFirstObj);
      if (Object.values(rewardNode).length > 0) {
        setLevel(3);
      } else {
        setLevel(1);
      }
      reset({
        title: "",
        description: "",
        img: "",
        units_to_redeem: "",
        units_per_cash: "",
      });
      setIsActive(false);
      setRewardDetails({});
      setIsEdit(false);

      setIsSelectCash(false);
      setSelectedWrapperIdList({});
      setSelectedWrapperIdListInitial({});
    }
  }, [isOpen]);

  const handleSubmitData = handleSubmit(async (data) => {
    if (level === 1) {
      setLevel(3);
    } else {
      const formData: any = {};
      formData.title = data.title;
      formData.description = data.description;
      formData.img = data.img;
      formData.product_id = programId;

      if (isSelectCash) {
        formData.units_per_cash = data.units_per_cash;
        formData.type = "cash";
        if (isEdit) {
          handleEditRewards(formData, editableRewardId);
        } else {
          handleCreateRewards(formData);
        }
      } else {
        if (isEditDetails) {
          formData["products"] = rewardNode[editableRewardId]?.products;
          formData.units_to_redeem = data.units_to_redeem;
          handleSubmitRewardsTypePointsEdit(formData, editableRewardId);
        } else {
          formData.units_to_redeem = data.units_to_redeem;
          setRewardDetails(formData);
          setLevel(4);
        }
      }
    }
  });

  const handleSelectProductOrCash = () => {
    setIsSelectCash(!isSelectCash);
    // setLevel(level + 1);
  };

  const handleSelectProduct = (
    event: Event,
    productWrapper: { id: string },
  ) => {
    // Create a shallow copy of the selectedWrapperIdList object
    const cloneSelectedWrapperIdList = { ...selectedWrapperIdList };

    // Check if the productWrapper.id exists in the cloned object
    if (cloneSelectedWrapperIdList[productWrapper.id]) {
      // If it exists, delete the property from the cloned object
      delete cloneSelectedWrapperIdList[productWrapper.id];
    } else {
      // If it does not exist, add it with default values
      cloneSelectedWrapperIdList[productWrapper.id] = {
        id: productWrapper.id,
        surcharge: "0.00",
        include_modifiers: false,
      };
    }

    // Update the state with the modified object
    setSelectedWrapperIdList(cloneSelectedWrapperIdList);
  };

  const handleSubmitRewardsTypePointss = (
    selectedWrapperIdList: any,
    isEdit: any,
    rewardId: any,
  ) => {
    if (isEditPoint) {
      handleSubmitRewardsTypePointsEdits(
        editableRewardDetails,
        selectedWrapperIdList,
        isEdit,
        rewardId,
      );
    } else {
      handleSubmitRewardsTypePoints(selectedWrapperIdList, isEdit, rewardId);
    }
  };

  const handleDisable = () => {};
  return (
    <>
      <DialogCommonDefault
        open={isOpen}
        setOpen={setIsOpen}
        isNeedFixedHeight={level === 1 || level === 3 ? false : true}
        maxWidth={level === 1 || level === 3 ? "sm" : "xl"}
      >
        {level === 1 && (
          <StepLevelOne
            isSelectCash={isSelectCash}
            handleSelectProductOrCash={handleSelectProductOrCash}
            setIsOpen={setIsOpen}
            handleDisable={handleDisable}
            level={level}
            handleSubmitData={handleSubmitData}
            isOwner={isOwner}
          />
        )}

        {level === 3 && (
          <StepLevelThree
            isSelectCash={isSelectCash}
            register={register}
            setIsOpen={setIsOpen}
            isPointsReset={isPointsReset}
            setIsPointsReset={setIsPointsReset}
            errors={errors}
            isLoadingPage={isLoadingPage}
            handleSubmitData={handleSubmitData}
            isEdit={isEdit}
            isOwner={isOwner}
            selectedStrategyNode={selectedStrategyNode}
            isLoadingButton={isLoadingButton}
            isEditDetails={isEditDetails}
            setIsEditDetails={setIsEditDetails}
            rewardNode={rewardNode}
          />
        )}

        {level === 0 && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div style={{ marginTop: "14%" }}>
              <CircularProgress color="secondary" disableShrink />
            </div>
          </div>
        )}
      </DialogCommonDefault>

      {level === 4 && (
        <PointsModalContent
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleSelectProduct={handleSelectProduct}
          selectedWrapperIdList={selectedWrapperIdList}
          setSelectedWrapperIdList={setSelectedWrapperIdList}
          handleSubmitRewardsTypePoints={handleSubmitRewardsTypePointss}
          isEdit={isEdit}
          rewardId={editableRewardId}
          selectedWrapperIdListInitial={selectedWrapperIdListInitial}
          isLoadingButton={isLoadingButton}
          isOwner={isOwner}
          setIsEditPoint={setIsEditPoint}
          setIsEditDetails={setIsEditDetails}
        />
      )}
    </>
  );
};

export default RewardsAddModal;

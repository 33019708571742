import React from "react";
import _ from "lodash";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Grid, Typography } from "@material-ui/core";
import "jspdf-autotable";

import CardCommon from "../../../../components/card/CardCommon";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";

export interface CustomerNodeProps {
  banner: any;
  handleEditBannerDetails: any;
  handleDeleteBannerDetails: any;
  isOwner: any;
}

const BannerInfoNode: React.FunctionComponent<CustomerNodeProps> = ({
  banner,
  handleEditBannerDetails,
  handleDeleteBannerDetails,
  isOwner,
}) => {
  return (
    <>
      <CardCommon backgroundColor={"entity_background"}>
        <Grid container style={{ height: "40px", placeItems: "center" }}>
          <Grid
            item
            xs={4}
            style={{
              display: "flex",
              justifyContent: "start",
              paddingLeft: "12px",
            }}
          >
            <Typography> {banner.title}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography> {banner.subTitle}</Typography>
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              display: "flex",
              justifyContent: "end",
              paddingRight: "12px",
              alignItems: "center",
            }}
          >
            <Typography style={{ paddingRight: "12px" }}>
              {banner.description}
            </Typography>
            <ButtonCommon
              style={{
                fontSize: 11,
                marginRight: "12px",
                minWidth: "28px",
                height: "36px",
              }}
              disabled={!isOwner}
              variant="contained"
              color="yellow"
              onClick={() => handleEditBannerDetails(banner)}
            >
              <EditIcon />
            </ButtonCommon>
            <ButtonCommon
              style={{
                fontSize: 11,
                minWidth: "28px",
                height: "36px",
              }}
              disabled={!isOwner}
              variant="contained"
              color="red"
              onClick={() => handleDeleteBannerDetails(banner.bannerId)}
            >
              <DeleteIcon />
            </ButtonCommon>
          </Grid>
        </Grid>
      </CardCommon>
    </>
  );
};

export default BannerInfoNode;

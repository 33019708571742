import React from "react";
import _ from "lodash";
import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import "jspdf-autotable";

import { CustomTheme } from "../../../../types/customTheme";
import CardCommon from "../../../../components/card/CardCommon";

export interface CustomerNodeProps {}

const useStyles = makeStyles((theme: CustomTheme) => createStyles({}));

const BannerHeader: React.FunctionComponent<CustomerNodeProps> = ({}) => {
  return (
    <>
      <CardCommon backgroundColor={"table_header_background"}>
        <Grid container style={{ height: "32px", placeItems: "center" }}>
          <Grid
            item
            xs={4}
            style={{
              display: "flex",
              justifyContent: "start",
              paddingLeft: "12px",
            }}
          >
            <Typography>Title</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>Sub Title</Typography>
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              display: "flex",
              justifyContent: "end",
              paddingRight: "122px",
            }}
          >
            <Typography>Description</Typography>
          </Grid>
        </Grid>
      </CardCommon>
    </>
  );
};

export default BannerHeader;

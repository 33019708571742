import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  Cell,
} from "@table-library/react-table-library/table";
import { useLocation } from "react-router";
import GetAppIcon from "@material-ui/icons/GetApp";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";
import {
  Button,
  createStyles,
  FormControlLabel,
  Grid,
  InputAdornment,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  HeaderCellSort,
  useSort,
} from "@table-library/react-table-library/sort";
import "jspdf-autotable";
import { Icon } from "@iconify/react";
import { useTree } from "@table-library/react-table-library/tree";

import CardCommon from "../../../../components/card/CardCommon";
import MenuButtonCommon from "../../../../components/buttons/MenuButtonCommon";
import TextfieldCommon from "../../../../components/textField/TextfieldCommon";
import { handleDownloadPdf } from "./convertPdfExcel/convertDataToPdf";
import { handleDownloadCsv } from "./convertPdfExcel/convertDataToExcel";
import { CustomTheme } from "../../../../types/customTheme";
import deliverect from "../../../../assets/images/logos/Channel/deliverect.png";
import deliveroo from "../../../../assets/images/logos/Channel/deliveroo.png";
import eatpresto from "../../../../assets/images/logos/Channel/eatpresto.png";
import justeat from "../../../../assets/images/logos/Channel/justeat.png";
import ubereats from "../../../../assets/images/logos/Channel/ubereats.png";
import presto from "../../../../assets/images/logos/Channel/presto.png";
import kiosk from "../../../../assets/images/logos/Platform/kiosk.png";
import online from "../../../../assets/images/logos/Platform/online.png";
import other from "../../../../assets/images/logos/Platform/other.png";
import phone from "../../../../assets/images/logos/Platform/phone.png";
import walkin from "../../../../assets/images/logos/Platform/walkin.png";

import {
  checkIncludedValue,
  sortDates,
  sortNumbers,
  sortStrings,
} from "../../../../utils/commonArrayMap";
import LocationDetailsCard from "../../../../components/common/LocationDetailsCard";
import { convertDateTimeFormat } from "../../../../utils/ConvertDateTimeFormat";
import SwitchCommon from "../../../../components/switches/SwitchCommon";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { buttonColors } from "../../../../utils/enum";
import {
  getLocalStore,
  setLocalStore,
} from "../../../../utils/store/localStore";
import EditModal from "./editModal";
import {
  channelSelectorObj,
  defaultModeSelectorObj,
  platformSelectorObj,
} from "../../../../utils/consts/list";

export interface SaleCategoryReportInfoNodeProps {
  nodeList: any;
  filterDetails: any;
  locationSelectedList: any;
  handleOnClickText: any;
  userList: any;
  isActiveUserSwitch: any;
  setIsActiveUserSwitch: any;
  setIsOpenSkeletonLoading: any;
  isCategoryError: any;
  nodes: any;
  setNodes: any;
  discountInfo: any;
}

const useStyles = (nodesHeadersLength: number) =>
  makeStyles((theme: CustomTheme) =>
    createStyles({
      startTextStyle: {
        display: "flex",
        color: theme.palette.text.primary,
        justifyContent: "start",
        paddingLeft: "16px",
      },
      imgStyle: {
        width: "35px",
        height: "35px",
        [theme.breakpoints.down("sm")]: {
          width: "35px",
          height: "35px",
        },
        [theme.breakpoints.down("xs")]: {
          width: "30px",
          height: "30px",
        },
      },
      startNotNodeTextStyle: {
        display: "flex",
        color: theme.palette.text.primary,
        justifyContent: "start",
      },
      startHeaderTextStyle: {
        fontSize: "12px",
        display: "flex",
        color: theme.palette.custom.orange.contrastText,
        justifyContent: "start",
        paddingLeft: "16px",
      },
      textStyleHeader: {
        display: "flex",
        justifyContent: "center",
        fontSize: "12px",
        color: theme.palette.custom.orange.contrastText,
      },
      textStyle: {
        display: "flex",
        justifyContent: "center",
        fontSize: "12px",
      },
      textStyleFooter: {
        display: "flex",
        justifyContent: "center",
        fontSize: "12px",
        color: "white",
      },
      finalTotalRow: {
        height: "52px",
        [`&:nth-of-type(n)`]: {
          color: theme.palette.custom.green.contrastText,
          backgroundColor: theme.palette.custom.green.main,
        },
      },
      headerStyle: {
        "&:last-of-type": {
          backgroundColor: theme.palette.background.entity_background,
          color: theme.palette.custom.orange.contrastText,
        },
      },
      mainRow: {
        "&.css-16gtl2w": {
          display: "flex",
          justifyContent: "center",
        },
        "&:nth-of-type(n)": {
          color: theme.palette.custom.blue.contrastText,
          backgroundColor: theme.palette.custom.blue.main,
        },
      },
      normalRow: {
        "&.css-16gtl2w": {
          display: "flex",
          justifyContent: "center",
        },
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.background.paper,
        },
        "&:nth-of-type(even)": {
          backgroundColor: theme.palette.background.entity_background,
        },
      },
      baseCellRowPlatformChannelLast: {
        placeItems: "center",
        fontSize: "12px",
        "& .css-16gtl2w": {
          display: "flex",
          justifyContent: "end",
          paddingRight: "16px",
        },
        height: "52px",
        left: "0px",
        minWidth: `${8}%`,
        width: `${8}%`,
      },
      baseCellRowPlatformChannel: {
        placeItems: "center",
        fontSize: "12px",
        "& .css-16gtl2w": {
          display: "flex",
          justifyContent: "center",
        },
        height: "52px",
        left: "0px",
        minWidth: `${8}%`,
        width: `${8}%`,
      },
      baseCellRow: {
        placeItems: "center",
        fontSize: "12px",
        height: "52px",
        "&:nth-of-type(1)": {
          left: "0px",
          minWidth: "14%",
          width: "14%",
        },
        [`&:nth-of-type(n+2):nth-of-type(-n+${nodesHeadersLength - 1})`]: {
          minWidth: `${
            70 / (nodesHeadersLength > 10 ? 10 : nodesHeadersLength - 3)
          }%`,
          width: `${
            70 / (nodesHeadersLength > 10 ? 10 : nodesHeadersLength - 3)
          }%`,
          "& .css-16gtl2w": {
            display: "flex",
            justifyContent: "center",
          },
        },
        [`&:nth-of-type(${nodesHeadersLength})`]: {
          minWidth: `${
            70 / (nodesHeadersLength > 10 ? 10 : nodesHeadersLength - 3)
          }%`,
          width: `${
            70 / (nodesHeadersLength > 10 ? 10 : nodesHeadersLength - 3)
          }%`,
          "& .css-16gtl2w": {
            display: "flex",
            justifyContent: "end",
            paddingRight: "12px",
          },
        },
        [theme.breakpoints.down("md")]: {
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: `${
              70 / (nodesHeadersLength > 8 ? 8 : nodesHeadersLength - 3)
            }%`,
            width: `${
              70 / (nodesHeadersLength > 8 ? 8 : nodesHeadersLength - 3)
            }%`,
          },
          [`&:nth-of-type(n+2):nth-of-type(-n+${nodesHeadersLength - 1})`]: {
            minWidth: `${
              70 / (nodesHeadersLength > 8 ? 8 : nodesHeadersLength - 3)
            }%`,
            width: `${
              70 / (nodesHeadersLength > 8 ? 8 : nodesHeadersLength - 3)
            }%`,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
            },
          },
          [`&:nth-of-type(${nodesHeadersLength})`]: {
            minWidth: `${
              70 / (nodesHeadersLength > 8 ? 8 : nodesHeadersLength - 3)
            }%`,
            width: `${
              70 / (nodesHeadersLength > 8 ? 8 : nodesHeadersLength - 3)
            }%`,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              paddingRight: "12px",
            },
          },
        },
        [theme.breakpoints.down("sm")]: {
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: `${
              70 / (nodesHeadersLength > 7 ? 6 : nodesHeadersLength - 3)
            }%`,
            width: `${
              70 / (nodesHeadersLength > 7 ? 6 : nodesHeadersLength - 3)
            }%`,
          },
          [`&:nth-of-type(n+2):nth-of-type(-n+${nodesHeadersLength - 1})`]: {
            minWidth: `${
              70 / (nodesHeadersLength > 7 ? 6 : nodesHeadersLength - 3)
            }%`,
            width: `${
              70 / (nodesHeadersLength > 7 ? 6 : nodesHeadersLength - 3)
            }%`,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
            },
          },
          [`&:nth-of-type(${nodesHeadersLength})`]: {
            minWidth: `${
              70 / (nodesHeadersLength > 7 ? 6 : nodesHeadersLength - 3)
            }%`,
            width: `${
              70 / (nodesHeadersLength > 7 ? 6 : nodesHeadersLength - 3)
            }%`,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              paddingRight: "12px",
            },
          },
        },
      },
      baseCellRowChangeColor: {
        fontSize: "12px",
        "&.td:nth-of-type(n)": {
          color: theme.palette.custom["purple"].contrastText,
          backgroundColor: theme.palette.custom.purple.main,
        },
        placeItems: "center",
        "& .css-16gtl2w": {
        },
      },
    }),
  );
// const updatedSalesReport = {
//   orderChannel: sale.orderChannel,
//   platform: sale.platform,
//   mode: sale.mode,
//   queOrderNo: sale.queOrderNo,
//   orderNo: sale.orderNo,
//   extQueOrderNo: sale.extQueOrderNo,
//   date: convertDateTimeFormat(sale.date),
//   totalAmount: sale.totalAmount,
//   discountValue: sale.discountValue,
//   discountGroup:
//     sale.discountGroup === "-99" ? "0" : sale.discountGroup,
//   userId: userList[sale.userId]?.label || sale.userId,
// };

const editableList = [
  { id: "date", label: "Date" },
  { id: "orderNo", label: "Order No" },
  { id: "mode", label: "Mode" },
  { id: "totalAmount", label: "Amount" },
  { id: "discountValue", label: "Discount" },
  { id: "discountGroup", label: "Discount Group" },
  { id: "userId", label: "User" },
  { id: "platform", label: "Platform" },
  { id: "orderChannel", label: "Channel" },
  { id: "extQueOrderNo", label: "Channel Order No" },
];

/**
 * SalesReportInfoNode Component
 *
 * Component for rendering a sale category report information node.
 * This component comprises a grid layout with location details, search functionality,
 * and export options. It also includes a table to display sale category report data,
 * with various columns like item name, amount, quantity, discount, VAT percent, and VAT amount.
 * Each row in the table represents a specific item, and the footer row displays total values.
 * The component utilizes various Material-UI components like Grid, Card, Table, Typography,
 * Button, TextField, Menu, MenuItem, InputAdornment, etc., along with custom styles.
 * It includes event handlers for searching, exporting data as PDF or CSV, and clicking on items
 * to open them in a new window.
 * Additionally, it contains a useEffect hook to reset the nodes state when the "startDate"
 * parameter changes, ensuring that the component fetches new data based on the updated start date.
 */
const SalesReportInfoNode: React.FunctionComponent<
  SaleCategoryReportInfoNodeProps
> = ({
  nodeList,
  filterDetails,
  locationSelectedList,
  handleOnClickText,
  userList,
  isActiveUserSwitch,
  setIsActiveUserSwitch,
  setIsOpenSkeletonLoading,
  isCategoryError,
  nodes,
  setNodes,
  discountInfo,
}) => {
  const [pdfHeaders, setPdfHeaders] = useState<any>([]);
  const [headersList, setHeadersList] = useState<any>([]);
  const [editColumns, setEditColumns] = useState<any>([]);
  const [excelHeaders, setExcelHeaders] = useState<any>([]);

  const [nodesInitial, setNodesInitial] = useState<any>({ nodes: [] });
  const [nodesPdfExcel, setNodesPdfExcel] = useState<any>([]);
  const [saleIdList, setSaleIdList] = useState<any>([]);
  const [nodesPdfExcelInitial, setNodesPdfExcelInitial] = useState<any>([]);
  const [footer, setFooter] = useState<any>([]);
  const [footerInitial, setFooterInitial] = useState<any>([]);
  const [bodyLength, setBodyLength] = useState(0);
  const [bodyLengthInitial, setBodyLengthInitial] = useState(0);
  const [disableSalesUpdate, setDisableSalesUpdate] = useState<any>([]);
  const [disableSales, setDisableSales] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [saleErrorList, setSaleErrorList] = useState<any>([]);
  const [columnLength, setColumnLength] = useState(0);
  const [lastColumn, setLastColumn] = useState<any>();

  const open = Boolean(anchorEl);
  const { search } = useLocation();
  const maxWidth = useMediaQuery("(max-width: 1980px)");
  const maxWidthCustom = useMediaQuery("(max-width: 2600px)");
  const params = new URLSearchParams(search);

  /**
   * This useEffect hook is responsible for resetting the state of "nodes" to an empty array
   * whenever the value of the "startDate" parameter changes. It ensures that the component
   * fetches new data based on the updated start date.
   */
  useEffect(() => {
    // Resetting the state of "nodes" to an empty array
    setNodes({ nodes: [] });
  }, [params.get("startDate")]);

  /**
   * This useEffect hook is responsible for processing the "nodeList"
   * and generating the necessary data for rendering the table and creating a PDF.
   * It calculates totals, handles duplicate items, prepares data for display and PDF export,
   * and sets necessary state variables for the component.
   */
  useEffect(() => {
    // Deep clone of the nodeList
    let cloneSalesReportNodeList: any = _.cloneDeep(nodeList);
    let salesReport: any = [];
    let salesReportPdf: any = [];

    // Process each key (group) in the nodeList
    if (!_.isEmpty(cloneSalesReportNodeList) && !_.isEmpty(userList)) {
      const disableCategory = getLocalStore("hiddenSale") || [];

      cloneSalesReportNodeList.map((sale: any) => {
        let updatedSalesReport: any = {};
        let updatedSalesReportPdf: any = {};
        editableList.map((data: any) => {
          if (
            !disableCategory.includes(data.id) &&
            ((parseFloat(sale.discountValue) > 0 && isActiveUserSwitch) ||
              !isActiveUserSwitch)
          ) {
            if (data?.id === "date") {
              updatedSalesReport[data?.id] = convertDateTimeFormat(sale.date);
              updatedSalesReportPdf[data?.id] = convertDateTimeFormat(
                sale.date,
              );
            } else if (data?.id === "discountGroup") {
              updatedSalesReport[data?.id] =
                parseFloat(sale.discountGroup) <= 0
                  ? ""
                  : discountInfo[sale.discountGroup]?.title;
              updatedSalesReportPdf[data?.id] =
                parseFloat(sale.discountGroup) <= 0
                  ? ""
                  : discountInfo[sale.discountGroup]?.title;
            } else if (data?.id === "userId") {
              updatedSalesReport[data?.id] = userList[sale.userId]?.label
                ? userList[sale.userId]?.label
                : sale.userId;
              updatedSalesReportPdf[data?.id] = userList[sale.userId]?.label
                ? userList[sale.userId]?.label
                : sale.userId;
            } else if (data?.id === "totalAmount") {
              updatedSalesReport[data?.id] =
                sale.totalAmount.toString() === "0"
                  ? "0"
                  : parseFloat(sale.totalAmount).toFixed(2);
              updatedSalesReportPdf[data?.id] =
                sale.totalAmount.toString() === "0"
                  ? "0"
                  : parseFloat(sale.totalAmount).toFixed(2);
            } else if (data?.id === "discountValue") {
              updatedSalesReport[data?.id] =
                sale.discountValue.toString() === "0"
                  ? ""
                  : parseFloat(sale.discountValue).toFixed(2);
              updatedSalesReportPdf[data?.id] =
                sale.discountValue.toString() === "0"
                  ? ""
                  : parseFloat(sale.discountValue).toFixed(2);
            } else if (data?.id === "orderChannel") {
              updatedSalesReport[data?.id] =
                sale?.["platform"] === "online"
                  ? channelSelectorObj?.[sale?.orderChannel]
                    ? channelSelectorObj?.[sale?.orderChannel]?.label
                    : ""
                  : "";
              console.log(
                "asdas34asfsdfgg",
                sale?.["platform"] === "Online"
                  ? channelSelectorObj?.[sale?.orderChannel]
                    ? channelSelectorObj?.[sale?.orderChannel]?.label
                    : ""
                  : "",
              );

              updatedSalesReportPdf[data?.id] = channelSelectorObj?.[
                sale?.orderChannel
              ]
                ? channelSelectorObj?.[sale?.orderChannel]?.label
                : "";
            } else if (data?.id === "platform") {
              updatedSalesReport[data?.id] = platformSelectorObj?.[
                sale?.platform
              ]
                ? platformSelectorObj?.[sale?.platform]?.label
                : "";

              updatedSalesReportPdf[data?.id] = platformSelectorObj?.[
                sale?.platform
              ]
                ? platformSelectorObj?.[sale?.platform]?.label
                : "";
            } else if (data?.id === "mode") {
              updatedSalesReport[data?.id] = defaultModeSelectorObj[sale.mode]
                ? defaultModeSelectorObj[sale.mode]?.label
                : sale?.[data?.id];
              updatedSalesReportPdf[data?.id] = defaultModeSelectorObj[
                sale.mode
              ]
                ? defaultModeSelectorObj[sale.mode]?.label
                : sale?.[data?.id];
            } else {
              updatedSalesReport[data?.id] = sale?.[data?.id];
              updatedSalesReportPdf[data?.id] = sale?.[data?.id];
            }
          }
        });
        if (Object.keys(updatedSalesReport).length > 0) {
          salesReport.push(updatedSalesReport);
          salesReportPdf.push(updatedSalesReportPdf);
        }
      });

      // const updatedSalesReport = {
      //   orderChannel: sale.orderChannel,
      //   platform: sale.platform,
      //   mode: sale.mode,
      //   queOrderNo: sale.queOrderNo,
      //   orderNo: sale.orderNo,
      //   extQueOrderNo: sale.extQueOrderNo,
      //   date: convertDateTimeFormat(sale.date),
      //   totalAmount: sale.totalAmount,
      //   discountValue: sale.discountValue,
      //   discountGroup:
      //     sale.discountGroup === "-99" ? "0" : sale.discountGroup,
      //   userId: userList[sale.userId]?.label || sale.userId,
      // };
      // Set table header

      setEditColumns(editableList);

      const tableHeaderList: any = [];
      const pdfHeaderList: any = [];
      const excelHeaderList: any = [];

      editableList.map((data: any) => {
        if (!disableCategory.includes(data.id)) {
          tableHeaderList.push(data.label);
          pdfHeaderList.push({ title: data.label, field: data.id });
          excelHeaderList.push({
            header: data.label,
            key: data.id,
            width: 52,
            height: 68,
          });
        }
      });
      setHeadersList(tableHeaderList);
      setLastColumn(pdfHeaderList[pdfHeaderList.length - 1]);
      setColumnLength(tableHeaderList.length);
      // Set PDF header
      setPdfHeaders(pdfHeaderList);

      // set Excel header
      setExcelHeaders(excelHeaderList);

      setNodesPdfExcel(salesReportPdf);
      setNodesPdfExcelInitial(salesReport);
      setNodes({ nodes: salesReport });
      setNodesInitial({ nodes: salesReport });
      setBodyLength(salesReport.length);
      setBodyLengthInitial(salesReport.length);
    }
  }, [nodeList, userList, disableSalesUpdate]);
  // Create a pay In Out sorting function
  const sort = useSort(
    nodes,
    {},
    {
      sortFns: {
        Date: (array) => sortDates(array, "date"),
        "Order No": (array) => sortNumbers(array, "orderNo"),
        Mode: (array) => sortStrings(array, "mode"),
        Amount: (array) => sortNumbers(array, "totalAmount"),
        Discount: (array) => sortNumbers(array, "discountValue"),
        "Discount Group": (array) => sortStrings(array, "discountGroup"),
        User: (array) => sortStrings(array, "userId"),
        Platform: (array) => sortStrings(array, "platform"),
        Channel: (array) => sortStrings(array, "orderChannel"),
        "Channel Order No": (array) => sortStrings(array, "extQueOrderNo"),
      },
      sortIcon: {
        margin: "0px",
        iconDefault: (
          <Icon
            icon="fluent:chevron-up-down-16-filled"
            width="44"
            height="44"
          />
        ),
        iconUp: <ArrowDropDownIcon />,
        iconDown: <ArrowDropUpIcon />,
      },
    },
  );

  /* Open PDF / Excel selection menu */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /* Close PDF / Excel selection menu */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes: any = useStyles(columnLength)();

  /* Change row span icon */
  const tree = useTree(
    nodes,
    {},
    {
      treeIcon: {
        margin: "4px",
        iconDefault: <ArrowDropDownIcon />,
        iconRight: <ArrowDropDownIcon />,
        iconDown: <ArrowDropUpIcon />,
      },
    },
  );

  const handelOrderChannel = (platform: any, orderChannel: any) => {
    /* The channel will be included only if the platform is online. Otherwise, the presto logo will be entered for the channel. */
    if (platform === "Online") {
      console.log("sadfsdgfdgfdhswdfsdf", orderChannel);
      if (orderChannel === "Eat Presto") {
        return eatpresto;
      } else if (orderChannel === "Deliverect") {
        return deliverect;
      } else if (orderChannel === "Just Eat") {
        return justeat;
      } else if (orderChannel === "Uber Eats") {
        return ubereats;
      } else if (orderChannel === "Deliveroo") {
        return deliveroo;
      } else if (orderChannel === "presto") {
        return presto;
      } else {
        return presto;
      }
    } else if (!platform) {
      if (orderChannel === "Eat Presto") {
        return eatpresto;
      } else if (orderChannel === "Deliverect") {
        return deliverect;
      } else if (orderChannel === "Just Eat") {
        return justeat;
      } else if (orderChannel === "Uber Eats") {
        return ubereats;
      } else if (orderChannel === "Deliveroo") {
        return deliveroo;
      } else if (orderChannel === "presto") {
        return presto;
      } else {
        return presto;
      }
    }
  };

  const handelOrderChannelHoverText = (platform: any, orderChannel: any) => {
    /* The channel will be included only if the platform is online. Otherwise, the presto logo will be entered for the channel. */
    if (platform === "Online") {
      if (orderChannel === "Eat Presto") {
        return "Eatpresto";
      } else if (orderChannel === "Deliverect") {
        return "Deliverect";
      } else if (orderChannel === "Just Eat") {
        return "Justeat";
      } else if (orderChannel === "Uber Eats") {
        return "Ubereats";
      } else if (orderChannel === "Deliveroo") {
        return "Deliveroo";
      } else if (orderChannel === "presto") {
        return "Presto";
      } else {
        return "Presto";
      }
    } else {
      return "presto";
    }
  };

  const handelPlatform = (platform: any) => {
    /* Set the platform image. */
    if (platform === "Kiosk") {
      return kiosk;
    } else if (platform === "Online") {
      return online;
    } else if (platform === "Phone") {
      return phone;
    } else if (platform === "Walk In") {
      return walkin;
    } else {
      return other;
    }
  };

  const handelPlatformHoverText = (platform: any, orderChannel: any) => {
    /* Set the platform image. */
    if (platform === "kiosk") {
      return "Kiosk";
    } else if (platform === "online") {
      return "Online";
    } else if (platform === "phone") {
      return "Phone";
    } else if (platform === "walk in") {
      return "Walk in";
    } else {
      return "Unknown";
    }
  };

  const handleActivateSwitch = () => {
    setIsActiveUserSwitch(!isActiveUserSwitch);
    setIsOpenSkeletonLoading(true);
    setTimeout(() => {
      setIsOpenSkeletonLoading(false);
    }, 1000);
  };

  const handleOpenEditModal = () => {
    setIsOpenEditModal(true);
  };

  const handleSubmit = () => {
    const keys: any = [];
    setDisableSalesUpdate(JSON.stringify(disableSales));

    editColumns.map((data: any) => {
      if (
        !keys.includes(data.id) &&
        !JSON.stringify(disableSales).includes(data.id)
      ) {
        keys.push(data.id);
      }
    });

    setIsOpenSkeletonLoading(true);

    setTimeout(() => {
      setIsOpenSkeletonLoading(false);
    }, 3000);

    setLocalStore("hiddenSale", JSON.stringify(disableSales));
    setIsOpenEditModal(false);
    setSaleIdList(keys);
  };

  useEffect(() => {
    const disableCategory = getLocalStore("hiddenSale");
    if (!_.isEmpty(JSON.parse(disableCategory))) {
      setDisableSales(JSON.parse(disableCategory));
      setDisableSalesUpdate(JSON.parse(disableCategory));
    }
  }, []);

  const handleHidden = (categoryId: any) => {
    const cloneDisableCategoryList = _.cloneDeep(disableSales);
    cloneDisableCategoryList.push(categoryId);

    setDisableSales(cloneDisableCategoryList);
  };

  const handleHVisible = (categoryId: any) => {
    const cloneDisableCategoryList = _.cloneDeep(disableSales);
    const filter = cloneDisableCategoryList.filter(
      (data: any) => data !== categoryId,
    );

    setDisableSales(filter);
  };

  const handleCloseModal = () => {
    setIsOpenEditModal(false);
    setDisableSales(disableSalesUpdate);
  };

  const handleStyle = (index: any, data: any) => {
    if (lastColumn?.field === "extQueOrderNo") {
      if (
        index === headersList.length - 2 ||
        index === headersList.length - 3
      ) {
        return classes.baseCellRowPlatformChannel;
      } else {
        return classes.baseCellRow;
      }
    } else if (lastColumn?.field === "orderChannel") {
      if (disableSalesUpdate.includes("platform")) {
        if (index === headersList.length - 1) {
          console.log("aaaaaaaaa4aaasdfsdf", data);

          return classes.baseCellRowPlatformChannelLast;
        } else {
          return classes.baseCellRow;
        }
      } else {
        if (index === headersList.length - 1) {
          return classes.baseCellRowPlatformChannelLast;
        } else if (index === headersList.length - 2) {
          return classes.baseCellRowPlatformChannel;
        } else {
          return classes.baseCellRow;
        }
      }
    } else if (lastColumn?.field === "platform") {
      if (index === headersList.length - 1) {
        return classes.baseCellRowPlatformChannelLast;
      } else {
        return classes.baseCellRow;
      }
    } else {
      return classes.baseCellRow;
    }
  };

  const maxWidthLocationChips = useMediaQuery("(max-width: 600px)");
  const theme: CustomTheme = useTheme();
  return (
    <>
      <>
        <Grid container spacing={2} style={{ marginTop: "8px" }}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
            }}
          >
            <div>
              <LocationDetailsCard
                locationSelectedList={locationSelectedList}
                handleOnClickText={handleOnClickText}
              />
            </div>
          </Grid>
        </Grid>
        <div
          style={
            maxWidthLocationChips
              ? { display: "block", marginTop: "8px", marginBottom: "20px" }
              : {
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "20px",
                }
          }
        >
          <div
            style={
              maxWidthLocationChips
                ? {
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "8px",
                  }
                : { display: "flex", marginLeft: "16px" }
            }
          >
            <FormControlLabel
              style={{ marginRight: "16px" }}
              control={<SwitchCommon />}
              checked={isActiveUserSwitch}
              onChange={handleActivateSwitch}
              label="Show Discounted"
              labelPlacement="start"
            />
            <ButtonCommon
              onClick={handleOpenEditModal}
              variant="contained"
              style={{
                fontSize: 11,
                width: "170px",
                marginRight: "14px",
                height: "40px",
              }}
              color={buttonColors.UPDATE_BUTTON_COLOR}
            >
              {"Show/Hide Columns"}
            </ButtonCommon>
            {!_.isEmpty(nodes.nodes) && (
              <>
                <MenuButtonCommon
                  id="basic-button"
                  variant="contained"
                  style={{
                    fontSize: 11,
                    height: "38px",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    width: "140px",
                  }}
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <GetAppIcon />
                  <Typography
                    variant="body1"
                    style={{ paddingLeft: "16px", color: "white" }}
                  >
                    Export
                  </Typography>
                </MenuButtonCommon>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    elevation: 0,
                    style: {
                      borderRadius: "10px",
                      border: `1px solid ${theme.palette.background.entity_border}`,
                    },
                  }}
                  style={{ top: "56px" }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleDownloadPdf(
                        "p",
                        pdfHeaders,
                        nodesPdfExcel,
                        filterDetails,
                      );
                      handleClose();
                    }}
                  >
                    Export as PDF
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleDownloadCsv(
                        excelHeaders,
                        nodesPdfExcel,
                        filterDetails,
                        bodyLength,
                      );
                      handleClose();
                    }}
                  >
                    Export as CSV
                  </MenuItem>
                </Menu>
              </>
            )}
          </div>
        </div>
      </>

      <CardCommon>
        <Table
          data={nodes}
          tree={tree}
          sort={sort}
          layout={{ custom: maxWidthCustom, horizontalScroll: maxWidth }}
          style={{ zIndex: 0 }}
        >
          {(tableList) => (
            <>
              <Header style={{ zIndex: 0 }}>
                <HeaderRow className={classes.headerStyle}>
                  {headersList.map((data: any, index: any) => (
                    <HeaderCellSort
                      sortKey={data}
                      pinLeft={data === headersList[0] ? true : false}
                      className={handleStyle(index, data)}
                    >
                      <Typography
                        variant="caption"
                        className={
                          index === 0
                            ? classes.startHeaderTextStyle
                            : classes.textStyleHeader
                        }
                        style={{fontWeight: "bold"}}
                      >
                        {data}
                      </Typography>
                    </HeaderCellSort>
                  ))}
                </HeaderRow>
              </Header>

              <Body className={classes.bodyStyle}>
                {tableList.map((item, bodyIndex) => (
                  <>
                    <Row
                      key={item.id}
                      item={item}
                      className={
                        item.nodes ? classes.mainRow : classes.normalRow
                      }
                    >
                      {!disableSalesUpdate.includes("date") && (
                        <Cell
                          className={classes.baseCellRow}
                          pinLeft={headersList[0] === "Date" ? true : false}
                        >
                          <Typography
                            variant="caption"
                            className={
                              headersList[0] === "Date"
                                ? classes.startTextStyle
                                : classes.startNotNodeTextStyle
                            }
                          >
                            {item.date}
                          </Typography>
                        </Cell>
                      )}
                      {!disableSalesUpdate.includes("orderNo") && (
                        <Cell
                          className={classes.baseCellRow}
                          pinLeft={headersList[0] === "Order No" ? true : false}
                        >
                          <Typography
                            variant="caption"
                            className={
                              headersList[0] === "Order No"
                                ? classes.startTextStyle
                                : classes.startNotNodeTextStyle
                            }
                            style={{
                              display: "flex",
                              justifyContent:
                                lastColumn?.field === "orderNo"
                                  ? "end"
                                  : headersList[0] === "Order No"
                                  ? "start"
                                  : "center",
                              paddingRight:
                                lastColumn?.field === "orderNo"
                                  ? "16px"
                                  : "0px",
                            }}
                          >
                            {item.orderNo}
                          </Typography>
                        </Cell>
                      )}

                      {!disableSalesUpdate.includes("mode") && (
                        <Cell
                          className={
                            headersList[0] === "Mode"
                              ? classes.startTextStyle
                              : classes.startNotNodeTextStyle
                          }
                          pinLeft={headersList[0] === "Mode" ? true : false}
                        >
                          <Typography
                            variant="caption"
                            className={classes.textStyle}
                            style={{
                              display: "flex",
                              justifyContent:
                                lastColumn?.field === "mode"
                                  ? "end"
                                  : headersList[0] === "Mode"
                                  ? "start"
                                  : "center",
                              paddingRight:
                                lastColumn?.field === "mode" ? "16px" : "0px",
                            }}
                          >
                            {item.mode}
                          </Typography>
                        </Cell>
                      )}

                      {!disableSalesUpdate.includes("totalAmount") && (
                        <Cell
                          className={
                            headersList[0] === "Amount"
                              ? classes.startTextStyle
                              : classes.startNotNodeTextStyle
                          }
                          pinLeft={headersList[0] === "Amount" ? true : false}
                        >
                          <Typography
                            variant="caption"
                            className={classes.textStyle}
                            style={{
                              display: "flex",
                              justifyContent:
                                lastColumn?.field === "totalAmount"
                                  ? "end"
                                  : headersList[0] === "Amount"
                                  ? "start"
                                  : "center",
                              paddingRight:
                                lastColumn?.field === "totalAmount"
                                  ? "16px"
                                  : "0px",
                            }}
                          >
                            {item.totalAmount}
                          </Typography>
                        </Cell>
                      )}

                      {!disableSalesUpdate.includes("discountValue") && (
                        <Cell
                          className={
                            headersList[0] === "Discount"
                              ? classes.startTextStyle
                              : classes.startNotNodeTextStyle
                          }
                          pinLeft={headersList[0] === "Discount" ? true : false}
                        >
                          <Typography
                            variant="caption"
                            className={classes.textStyle}
                            style={{
                              display: "flex",
                              justifyContent:
                                lastColumn?.field === "discountValue"
                                  ? "end"
                                  : headersList[0] === "Discount"
                                  ? "start"
                                  : "center",
                              paddingRight:
                                lastColumn?.field === "discountValue"
                                  ? "16px"
                                  : "0px",
                            }}
                          >
                            {item.discountValue}
                          </Typography>
                        </Cell>
                      )}

                      {!disableSalesUpdate.includes("discountGroup") && (
                        <Cell
                          className={
                            headersList[0] === "Discount Group"
                              ? classes.startTextStyle
                              : classes.startNotNodeTextStyle
                          }
                          pinLeft={
                            headersList[0] === "Discount Group" ? true : false
                          }
                        >
                          <Typography
                            variant="caption"
                            className={classes.textStyle}
                            style={{
                              display: "flex",
                              justifyContent:
                                lastColumn?.field === "discountGroup"
                                  ? "end"
                                  : headersList[0] === "Discount Group"
                                  ? "start"
                                  : "center",
                              paddingRight:
                                lastColumn?.field === "discountGroup"
                                  ? "16px"
                                  : "0px",
                            }}
                          >
                            {item.discountGroup}
                          </Typography>
                        </Cell>
                      )}
                      {!disableSalesUpdate.includes("userId") && (
                        <Cell
                          className={
                            headersList[0] === "User"
                              ? classes.startTextStyle
                              : classes.startNotNodeTextStyle
                          }
                          pinLeft={headersList[0] === "User" ? true : false}
                        >
                          <Typography
                            variant="caption"
                            className={classes.textStyle}
                            style={{
                              display: "flex",
                              justifyContent:
                                lastColumn?.field === "userId"
                                  ? "end"
                                  : headersList[0] === "User"
                                  ? "start"
                                  : "center",
                              paddingRight:
                                lastColumn?.field === "userId" ? "16px" : "0px",
                            }}
                          >
                            {item.userId}
                          </Typography>
                        </Cell>
                      )}

                      {!disableSalesUpdate.includes("platform") && (
                        <Cell
                          className={classes.baseCellRowPlatformChannel}
                          pinLeft={headersList[0] === "Platform" ? true : false}
                        >
                          <Typography
                            variant="caption"
                            className={
                              headersList[0] === "Platform"
                                ? classes.startTextStyle
                                : classes.startNotNodeTextStyle
                            }
                            style={{
                              display: "flex",
                              justifyContent:
                                lastColumn?.field === "platform"
                                  ? "end"
                                  : headersList[0] === "Platform"
                                  ? "start"
                                  : "center",
                              paddingRight:
                                lastColumn?.field === "platform"
                                  ? "16px"
                                  : "0px",
                            }}
                          >
                            <Tooltip
                              title={handelPlatformHoverText(
                                item.platform,
                                item.orderChannel,
                              )}
                            >
                              <img
                                src={handelPlatform(item.platform)}
                                className={classes.imgStyle}
                                alt="Platform image"
                              />
                            </Tooltip>
                          </Typography>
                        </Cell>
                      )}

                      {!disableSalesUpdate.includes("orderChannel") && (
                        <Cell
                          className={classes.baseCellRowPlatformChannel}
                          pinLeft={headersList[0] === "Channel" ? true : false}
                        >
                          {handelOrderChannel(
                            item.platform,
                            item.orderChannel,
                          ) && (
                            <Tooltip
                              title={handelOrderChannelHoverText(
                                item.platform,
                                item.orderChannel,
                              )}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent:
                                    lastColumn?.field === "orderChannel"
                                      ? "end"
                                      : headersList[0] === "Channel"
                                      ? "start"
                                      : "center",
                                  paddingRight:
                                    lastColumn?.field === "orderChannel"
                                      ? "16px"
                                      : "0px",
                                }}
                              >
                                <img
                                  src={handelOrderChannel(
                                    item.platform,
                                    item.orderChannel,
                                  )}
                                  className={classes.imgStyle}
                                  alt="Order channel image"
                                />
                              </div>
                            </Tooltip>
                          )}
                        </Cell>
                      )}

                      {!disableSalesUpdate.includes("extQueOrderNo") && (
                        <Cell className={classes.baseCellRow}>
                          <Typography
                            variant="caption"
                            className={classes.textStyle}
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              paddingRight: "16px",
                            }}
                          >
                            {item.extQueOrderNo}
                          </Typography>
                        </Cell>
                      )}
                    </Row>
                  </>
                ))}
              </Body>
            </>
          )}
        </Table>
      </CardCommon>

      <EditModal
        isOpen={isOpenEditModal}
        setIsOpen={setIsOpenEditModal}
        handleSubmit={handleSubmit}
        categoryList={isCategoryError ? saleErrorList : editColumns}
        handleHidden={handleHidden}
        handleHVisible={handleHVisible}
        disableSales={disableSales}
        handleClose={handleCloseModal}
      />
    </>
  );
};

export default SalesReportInfoNode;

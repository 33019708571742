import {
  DialogContent,
  DialogTitle,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import React from "react";
import _ from "lodash";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { CustomTheme } from "../../../../types/customTheme";
import RewardsDetailsNode from "./RewardsDetailsNode";

const useStyles = makeStyles((theme: any) => createStyles({}));

export interface CategoryMenuListProps {
  setIsEdit: any;
  setLevel: any;
  setIsOpen: any;
  rewardDetails: any;
  handleEditRewardDetails: any;
}

/**
 * StepLevelTwo Component: Category Menu List
 *
 * This component represents a list item within a category menu. It displays information about an item, including its name,
 * image (if available), and description. Users can interact with the item to view more details or perform actions.
 */
const StepLevelTwo: React.FunctionComponent<CategoryMenuListProps> = ({
  setIsEdit,
  setLevel,
  setIsOpen,
  rewardDetails,
  handleEditRewardDetails,
}) => {
  const theme: CustomTheme = useTheme();
  const classes = useStyles();
  return (
    <>
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">Rewards Details</Typography>
          <div>
            <ButtonCommon
              variant="contained"
              style={{ fontSize: 11, width: "100px", marginRight: 8 }}
              color="orange"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Cancel
            </ButtonCommon>
            <ButtonCommon
              variant="contained"
              style={{ fontSize: 11, width: "160px", marginRight: 8 }}
              color="green"
              onClick={() => {
                setIsEdit(false);
                setLevel(3);
              }}
            >
              Add New Rewards
            </ButtonCommon>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <RewardsDetailsNode
          rewardDetails={rewardDetails}
          handleEditRewardDetails={handleEditRewardDetails}
          setLevel={setLevel}
          setIsEdit={setIsEdit}
        />
      </DialogContent>
    </>
  );
};

export default StepLevelTwo;

import { Badge, IconButton, Typography, useTheme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import FilterCard from "./FilterCard/FilterCard";
import _ from "lodash";
import moment from "moment";
import TuneIcon from "@material-ui/icons/Tune";
import queryString from "query-string";

import DefaultAlert from "../../alerts/DefaultAlert";
import { useRouteMatch, useLocation, useHistory } from "react-router";
import {
  channelSelectorList,
  defaultModeSelectorList,
  partySizeSelectorList,
  payInOutSelectorList,
  paymentTypeSelectorList,
  platformSelectorList,
  typeSelectorList,
  vatPercentSelectorList,
} from "../../../utils/consts/list";
import {
  getFilterListFromArrayObjectAndArray,
  getLabelListFromArrayObjectAndArray,
} from "../../../utils/commonArrayMap";
import { handleFilterDataDetails } from "../../../utils/FilterDetails";
import { CustomTheme } from "../../../types/customTheme";
import { handleFilterData } from "../../../utils/FilterData";
import {
  convertDateToMoment,
  getEndDateDateMonthYear,
  getStartDateDateMonthYear,
} from "../../../utils/ConvertDateTimeFormat";
import handleFilterDataEatprestoOrder from "../../../utils/FilterDataEatprestoOrder";
import {
  eatprestoPaymentTypeSelectorList,
  orderStatusSelectorList,
  orderTypeSelectorList,
} from "../../../utils/consts/listArrays";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    iconButton: {
      "&:hover": {
        background: "none",
        backgroundColor: theme.palette.background.paper,
        border: `2px solid ${theme.palette.background.entity_border}`,
      },
      backgroundColor: theme.palette.background.entity_background,
      marginBottom: "8px",
      display: "flex",
      alignItems: "center",
      borderRadius: "10px",
      border: `2px solid ${theme.palette.background.entity_border}`,
      textAlign: "center",
      width: "120px",
      height: "44px",
    },
    badgeFontColor: {
      "& .MuiBadge-badge": {
        color: "white",
      },
    },
  }),
);

export interface Props {
  getFilterData: any;
  locationSelectorList: any;
  getLocationSelectorTypingList: any;
  isGetLocationInfo: any;
  getUserInfo?: any;
  userList?: any;
  setFilterDetails?: any;
  availableFilter: any;
  getPreviousFilterInfo?: any;
  setCurrentStartDate?: any;
  setCurrentEndDate?: any;
  setPreviousStartDate?: any;
  setPreviousEndDate?: any;
  previousStartDate?: any;
  previousEndDate?: any;
  isChart: any;
  payInOut?: any;
  isUserGet?: any;
  isSale?: any;
  isSaleItem?: any;
  isShift?: any;
  isOrder?: any;
  locationSelectedList: any;
  setLocationSelectedList: any;
  setOpenFilterCard: any;
  openFilterCard: any;
  isDailySale?: any;
  isWeekDateRange?: any;
  isInitialLoad?: any;
  setIsInitialLoad?: any;
  initialParamSize?: any;
  isOrderReport?: any;
  isRemoveBasedOnShift?: any;
  isRemoveDueAndOrderTime?: any;
  isNeedTerminal?: any;
  terminalSelectorList?: any;
  isActiveUserSwitch?: any;
  handleActivateSwitch?: any;
}

/**
 * Filter Component
 *
 * This React component is responsible for managing and applying filters to a dataset.
 * It provides a user-friendly interface for users to set filter criteria and adjust query parameters.
 * The component consists of a badge that displays the number of active filters and a filter card
 * that contains various filter controls such as text fields, dropdowns, date pickers, and switches.
 * Filters can be applied to refine the displayed data.
 *
 * Components and Features:
 * - Badge with the count of active filters.
 * - IconButton to toggle the filter card's visibility.
 * - FilterCard component that houses filter controls and options.
 * - Error alerts displayed when there are errors in filter settings.
 *
 * Props Passed to FilterCard Component:
 * - Various state variables and callback functions for managing filter settings and query parameters.
 *
 * Dependencies:
 * - Material-UI components for styling and user interface elements.
 *
 * Usage Example:
 * This component can be used in a web application to enhance the user's ability to filter and
 * narrow down a dataset based on specific criteria.
 */
const Filter: React.FunctionComponent<Props> = ({
  getFilterData,
  locationSelectorList,
  getLocationSelectorTypingList,
  isGetLocationInfo,
  getUserInfo,
  userList,
  setFilterDetails,
  availableFilter,
  getPreviousFilterInfo,
  setCurrentStartDate,
  setCurrentEndDate,
  setPreviousStartDate,
  setPreviousEndDate,
  previousStartDate,
  previousEndDate,
  isChart,
  payInOut,
  isUserGet,
  isSale,
  isSaleItem,
  isShift,
  isOrder,
  locationSelectedList,
  setLocationSelectedList,
  setOpenFilterCard,
  openFilterCard,
  isDailySale,
  isWeekDateRange,
  isInitialLoad,
  setIsInitialLoad,
  initialParamSize,
  isOrderReport,
  isRemoveBasedOnShift,
  isRemoveDueAndOrderTime,
  isNeedTerminal,
  terminalSelectorList,
  isActiveUserSwitch,
  handleActivateSwitch,
}) => {
  const { search } = useLocation();
  const params: any = new URLSearchParams(search);

  const [typeFilterList, setTypeFilterList] = useState([]);
  const [channelFilterList, setChannelFilterList] = useState([]);
  const [platformFilterList, setPlatformFilterList] = useState([]);
  const [paymentTypeFilterList, setPaymentTypeFilterList] = useState([]);
  const [orderStatusFilterList, setOrderStatusFilterList] = useState([]);
  const [deliveryTypeFilterList, setDeliveryTypeFilterList] = useState([]);
  const [userInitialList, setUserInitialList] = useState<any>([]);
  const [userFilterList, setUseFilterList] = useState([]);
  const [payInOrOutFilterList, setPayInOrOutFilterList] = useState([]);
  const [alTypeFilterList, setAlTypeFilterList] = useState([]);
  const [partySizeFilterList, setPartySizeFilterList] = useState([]);
  const [terminalFilterList, setTerminalFilterList] = useState([]);
  const [vatPercentFilterList, setVatPercentFilterList] = useState([]);
  const [discountValueMin, setDiscountValueMin] = useState("");
  const [totalAmountMin, setTotalAmountMin] = useState("");
  const [totalAmountMax, setTotalAmountMax] = useState("");
  const [filterTotalAmountMin, setFilterTotalAmountMin] = useState("");
  const [filterTotalAmountMax, setFilterTotalAmountMax] = useState("");
  const [discountValueMax, setDiscountValueMax] = useState("");
  const [qtyMin, setQtyMin] = useState("");
  const [qtyMax, setQtyMax] = useState("");
  const [amountMin, setAmountMin] = useState("");
  const [amountMax, setAmountMax] = useState("");
  const [orderTotalMin, setOrderTotalMin] = useState("");
  const [orderTotalMax, setOrderTotalMax] = useState("");
  const [receiptNo, setReceiptNo] = useState("");
  const [queOrderNo, setQueOrderNo] = useState("");
  const [orderNo, setOrderNo] = useState("");
  const [filterDiscountValueMin, setFilterDiscountValueMin] = useState("");
  const [filterDiscountValueMax, setFilterDiscountValueMax] = useState("");
  const [filterQtyMin, setFilterQtyMin] = useState("");
  const [filterQtyMax, setFilterQtyMax] = useState("");
  const [filterAmountMin, setFilterAmountMin] = useState("");
  const [filterAmountMax, setFilterAmountMax] = useState("");
  const [filterOrderTotalMin, setFilterOrderTotalMin] = useState("");
  const [filterOrderTotalMax, setFilterOrderTotalMax] = useState("");
  const [filterReceiptNo, setFilterReceiptNo] = useState("");
  const [filterQueOrderNo, setFilterQueOrderNo] = useState("");
  const [filterOrderNo, setFilterOrderNo] = useState("");
  const [error, setError] = useState("");
  const [locationFilterList, setLocationFilterList] = useState([]);
  const [typeSelectedList, setTypeSelectedList] = useState([]);
  const [partySizeSelectedList, setPartySizeSelectedList] = useState([]);
  const [terminalSelectedList, setTerminalSelectedList] = useState([]);
  const [vatPercentSelectedList, setVatPercentSelectedList] = useState([]);
  const [paymentTypeSelectedList, setPaymentTypeSelectedList] = useState([]);
  const [orderStatusSelectedList, setOrderStatusSelectedList] = useState([]);
  const [deliveryTpeSelectedList, setDeliveryTpeSelectedList] = useState([]);
  const [userSelectedList, setUserSelectedList] = useState([]);
  const [payInOutSelectedList, setPayInOutSelectedList] = useState([]);
  const [allTypeSelectedList, setAllTypeSelectedList] = useState([]);
  const [channelSelectedList, setChannelSelectedList] = useState([]);
  const [platformSelectedList, setPlatformChannelSelectedList] = useState([]);
  const [userSelectorList, setUserSelectorList] = useState<any>([]);
  const [isBasedOnShift, setIsBasedOnShift] = useState(false);
  const [isPreviousBasedOnShift, setPreviousIsBasedOnShift] = useState(false);
  const [orderAndDueTimeSwitch, setOrderAndDueTimeSwitch] = useState(true);
  const [isBasedOnShiftButtonDisabled, setIsBasedOnShiftButtonDisabled] =
    useState(false);
  const [searchLocationName, setSearchLocationName] = useState([]);
  const [countOfFilters, setCountOfFilters] = useState(0);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>(moment().format("YYYY-MM-DD"));
  const [dateSelectionRange, setSelectionRange] = useState<any>();
  const [previousDateSelectionRange, setPreviousDateSelectionRange] =
    useState<any>();
  const [isAppEmbedded, setIsAppEmbedded] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const match: any = useRouteMatch();

  useEffect(() => {
    if (isWeekDateRange) {
      setSelectionRange([
        {
          startDate: new Date(moment().subtract(6, "d").format("YYYY-MM-DD")),
          endDate: new Date(),
          key: "selection",
        },
      ]);
      setStartDate(moment().subtract(6, "d").format("YYYY-MM-DD"));
      setPreviousDateSelectionRange([
        {
          startDate: new Date(moment().subtract(7, "d").format("YYYY-MM-DD")),
          endDate: new Date(moment().subtract(13, "d").format("YYYY-MM-DD")),
          key: "selection",
        },
      ]);
    } else {
      setSelectionRange([
        {
          startDate: new Date(moment().subtract(30, "d").format("YYYY-MM-DD")),
          endDate: new Date(),
          key: "selection",
        },
      ]);

      setStartDate(moment().subtract(30, "d").format("YYYY-MM-DD"));
    }
  }, []);
  /* Only if user details related to locations are not empty and only one location is selected, 
    user data will be entered in the user select dropdown. */
  useEffect(() => {
    if (!_.isEmpty(userList)) {
      if (locationFilterList.length === 1) {
        setUserSelectorList(userList);
      }
    } else {
      setUserSelectorList([]);
    }
  }, [userList]);

  /**
   * Handles the selection of items for various filters and updates corresponding state variables.
   *
   * @param {string} selectorName - The name of the filter selector.
   * @param {Array} selectedList - The list of selected items for the filter.
   */
  const handleAllSelectedList = (selectorName: string, selectedList: any) => {
    // Create an array of selected item IDs.
    const selectedListIds = selectedList.map((item: any) => item.id);

    // Switch statement based on the dropdown selector name.
    switch (selectorName) {
      case "location":
        // Handle location filter.
        if (!_.isEmpty(selectedList)) {
          // Update location-related state variables.
          setLocationSelectedList(selectedList);
          setLocationFilterList(selectedListIds);

          // Obtaining user data related to locations.
          if (isUserGet && selectedListIds.length === 1) {
            selectedListIds.forEach((data: any) => {
              // Fetch user info for the selected location.
              getUserInfo(data);
            });
          }

          // Reset user-related state variables if multiple locations are selected.
          if (selectedListIds.length !== 1) {
            setUserSelectedList([]);
            setUseFilterList([]);
          }
        } else if (_.isEmpty(selectedList)) {
          // No locations selected, get current location.
          const filteredCurrentLocation = locationSelectorList.filter(
            (itm: any) => itm.id === match.params.locationId,
          );

          // Get current location id.
          const currentLocationId = filteredCurrentLocation.map(
            (selectObject: any) => selectObject.id,
          );

          // Update location-related state variables with current location.
          setLocationSelectedList(filteredCurrentLocation);
          setLocationFilterList(currentLocationId);
          // Obtaining user data using current locations.
          if (isUserGet && selectedListIds.length === 1) {
            getUserInfo(currentLocationId[0]);
          }
        }
        break;
      case "mode":
        // Handle mode filter.
        // Update mode-related state variables.
        setTypeSelectedList(selectedList);
        setTypeFilterList(selectedListIds);
        break;

      case "platform":
        // Handle platform filter.
        // Update platform-related state variables.
        setPlatformChannelSelectedList(selectedList);
        setPlatformFilterList(selectedListIds);

        // Reset channel-related state variables if "online" is not included.
        if (!selectedListIds.includes("online")) {
          setChannelSelectedList([]);
          setChannelFilterList([]);
        }
        break;

      case "channel":
        // Handle channel filter.
        // Update channel-related state variables.
        setChannelSelectedList(selectedList);
        setChannelFilterList(selectedListIds);
        break;

      case "partySize":
        // Handle partySize filter.
        // Update partySize-related state variables.
        setPartySizeSelectedList(selectedList);
        setPartySizeFilterList(selectedListIds);
        break;

      case "terminal":
        // Handle partySize filter.
        // Update partySize-related state variables.
        setTerminalSelectedList(selectedList);
        setTerminalFilterList(selectedListIds);
        break;

      case "vatPercent":
        // Handle vatPercent filter.
        // Map selected IDs to specific VAT values.
        const vatPercentIds = selectedListIds.reduce((acc: any, id: any) => {
          if (id === "5") {
            acc.push("5000", "5");
          } else if (id === "12.5") {
            acc.push("12500", "12.5");
          } else if (id === "20") {
            acc.push("20000", "20");
          } else if (id === "0") {
            acc.push("0");
          }
          return acc;
        }, []);
        // Update vatPercent-related state variables.
        setVatPercentSelectedList(selectedList);
        setVatPercentFilterList(vatPercentIds);
        break;

      case "paymentType":
        // Handle paymentType filter.
        // Update paymentType-related state variables.
        setPaymentTypeSelectedList(selectedList);
        setPaymentTypeFilterList(selectedListIds);
        break;

      case "user":
        // Handle user filter.
        // Update user-related state variables.
        setUserSelectedList(selectedList);
        setUseFilterList(selectedListIds);
        setUserInitialList([]);
        break;

      case "payInOut":
        // Handle payInOut filter.
        // Update payInOut-related state variables.
        setPayInOutSelectedList(selectedList);
        setPayInOrOutFilterList(selectedListIds);
        break;

      case "type":
        // Handle type filter.
        // Update type-related state variables.
        setAllTypeSelectedList(selectedList);
        setAlTypeFilterList(selectedListIds);
        break;

      case "orderStatus":
        // Handle orderStatus filter.
        // Update orderStatus-related state variables.
        setOrderStatusSelectedList(selectedList);
        setOrderStatusFilterList(selectedListIds);
        break;

      case "deliveryType":
        // Handle deliveryType filter.
        // Update deliveryType-related state variables.
        setDeliveryTpeSelectedList(selectedList);
        setDeliveryTypeFilterList(selectedListIds);
        break;

      default:
        break;
    }
  };

  /* This function is worked only for charts. Then the current date and the previous date are retrieved. */
  const handelChartDateRange = (range: any) => {
    // Convert the selected start and end dates to Moment.js objects.
    setCurrentStartDate(convertDateToMoment(range.selection.startDate));
    setCurrentEndDate(convertDateToMoment(range.selection.endDate));

    /* This will work if the selected start date and end date are the same as this month. */
    if (
      convertDateToMoment(range.selection.startDate) ===
        getStartDateDateMonthYear(new Date(), "month") &&
      convertDateToMoment(range.selection.endDate) ===
        getEndDateDateMonthYear(new Date(), "month")
    ) {
      handleChangePreviousDateRangeDefault({
        selection: {
          startDate: new Date(
            moment(range.selection.startDate)
              .subtract(1, "months")
              .startOf("month")
              .format("YYYY-MM-DD"),
          ),
          key: "selection",
          endDate: new Date(
            moment(range.selection.endDate)
              .subtract(1, "months")
              .endOf("month")
              .format("YYYY-MM-DD"),
          ),
        },
      });
    } else if (
      convertDateToMoment(range.selection.startDate) ===
        getStartDateDateMonthYear(
          new Date(
            moment(range.selection.startDate)
              .startOf("month")
              .format("YYYY-MM-DD"),
          ),
          "month",
        ) &&
      convertDateToMoment(range.selection.endDate) ===
        getEndDateDateMonthYear(
          new Date(
            moment(range.selection.startDate)
              .startOf("month")
              .format("YYYY-MM-DD"),
          ),
          "month",
        )
    ) {
      handleChangePreviousDateRangeDefault({
        selection: {
          startDate: new Date(
            moment(range.selection.startDate)
              .subtract(1, "months")
              .startOf("month")
              .format("YYYY-MM-DD"),
          ),
          key: "selection",
          endDate: new Date(
            moment(range.selection.endDate)
              .subtract(1, "months")
              .endOf("month")
              .format("YYYY-MM-DD"),
          ),
        },
      });
    } else if (
      /* This will work if the selected start date and end date are the same as this year. */
      convertDateToMoment(range.selection.startDate) ===
        getStartDateDateMonthYear(new Date(), "year") &&
      convertDateToMoment(range.selection.endDate) ===
        getEndDateDateMonthYear(new Date(), "year")
    ) {
      handleChangePreviousDateRangeDefault({
        selection: {
          startDate: new Date(
            moment().subtract(1, "y").startOf("year").format("YYYY-MM-DD"),
          ),
          key: "selection",
          endDate: new Date(
            moment().subtract(1, "y").endOf("year").format("YYYY-MM-DD"),
          ),
        },
      });
    } else if (
      /* This works when the current start date and current end date are equal and the previous start date and 
      previous end date are unequal. Here, The previous date is changed according to the current date. */
      convertDateToMoment(range.selection.startDate) ===
        convertDateToMoment(range.selection.endDate) &&
      convertDateToMoment(previousStartDate) !==
        convertDateToMoment(previousEndDate)
    ) {
      handleChangePreviousDateRangeDefault({
        selection: {
          startDate: new Date(
            moment(range.selection.startDate)
              .subtract(1, "d")
              .format("YYYY-MM-DD"),
          ),
          key: "selection",
          endDate: new Date(
            moment(range.selection.startDate)
              .subtract(1, "d")
              .format("YYYY-MM-DD"),
          ),
        },
      });
    } else {
      /* Changing the previous start date and end date according to the difference between the current start date and end date. */
      const diff: number =
        moment(range.selection.endDate).diff(
          moment(range.selection.startDate),
          "days",
        ) + 1;

      handleChangePreviousDateRangeDefault({
        selection: {
          startDate: new Date(
            moment(range.selection.startDate)
              .subtract(diff, "d")
              .format("YYYY-MM-DD"),
          ),
          key: "selection",
          endDate: new Date(
            moment(range.selection.startDate)
              .subtract(1, "d")
              .format("YYYY-MM-DD"),
          ),
        },
      });
    }
  };

  /* set the startDate and endDate using date range. */
  const handleChangeDateRange = (range: any) => {
    setStartDate(moment(range.selection.startDate).format("YYYY-MM-DD"));
    setEndDate(moment(range.selection.endDate).format("YYYY-MM-DD"));
    setSelectionRange([range.selection]);

    const diff: number = moment(range.selection.endDate).diff(
      moment(range.selection.startDate),
      "days",
    );
    if (diff === 0 && isChart && !isDailySale) {
      setPreviousIsBasedOnShift(isBasedOnShift);
      setIsBasedOnShift(false);
      setIsBasedOnShiftButtonDisabled(true);
    } else {
      if (isBasedOnShiftButtonDisabled) {
        setIsBasedOnShiftButtonDisabled(false);
        setIsBasedOnShift(isPreviousBasedOnShift);
      }
    }

    if (isChart) {
      handelChartDateRange(range);
    }
  };

  /* set the previous startDate and endDate using date range. */
  const handleChangePreviousDateRangeDefault = (range: any) => {
    setPreviousStartDate(convertDateToMoment(range.selection.startDate));
    setPreviousEndDate(convertDateToMoment(range.selection.endDate));
    setPreviousDateSelectionRange([range.selection]);
  };

  /* The previous date is changed according to the current date. 
    This is used when there is a difference in length between the current date range and the previous date range. */
  const handleChangePreviousDateRange = (range: any) => {
    /* This works when the current start date and current end date are equal and the previous start date and 
      previous end date are unequal. Here, The previous date is changed according to the current date. */
    if (
      startDate === endDate &&
      convertDateToMoment(range.selection.startDate) !==
        convertDateToMoment(range.selection.endDate)
    ) {
      setPreviousStartDate(
        moment(startDate).subtract("1", "day").format("YYYY-MM-DD"),
      );
      setPreviousEndDate(
        moment(endDate).subtract("1", "day").format("YYYY-MM-DD"),
      );
      setPreviousDateSelectionRange([
        {
          startDate: new Date(
            moment(startDate).subtract("1", "day").format("YYYY-MM-DD"),
          ),
          endDate: new Date(
            moment(endDate).subtract("1", "day").format("YYYY-MM-DD"),
          ),
          key: "selection",
        },
      ]);
    } else if (
      /* This works when the current start date and current end date are unequal and the previous start date and 
      previous end date are equal. Here, The previous date range is changed according to the current date range. */
      startDate !== endDate &&
      convertDateToMoment(range.selection.startDate) ===
        convertDateToMoment(range.selection.endDate)
    ) {
      const diff: number = moment(endDate).diff(moment(startDate), "days") + 1;
      setPreviousStartDate(
        moment(startDate).subtract(diff, "day").format("YYYY-MM-DD"),
      );
      setPreviousEndDate(
        moment(startDate).subtract("1", "day").format("YYYY-MM-DD"),
      );
      setPreviousDateSelectionRange([
        {
          startDate: new Date(
            moment(startDate).subtract(diff, "day").format("YYYY-MM-DD"),
          ),
          endDate: new Date(
            moment(startDate).subtract("1", "day").format("YYYY-MM-DD"),
          ),
          key: "selection",
        },
      ]);
    } else {
      handleChangePreviousDateRangeDefault(range);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(userInitialList)) {
      const filteredUserArray = userSelectorList.filter((itm: any) =>
        userInitialList.includes(itm.id),
      );
      handleAllSelectedList("user", filteredUserArray);
    }
  }, [userSelectorList]);

  /* On initial load, modify the data in the filter according to the query parameters. */
  useEffect(() => {
    const discountValueMax: any = params.get("discountValueMax");
    const discountValueMin: any = params.get("discountValueMin");
    const qtyMax: any = params.get("qtyMax");
    const qtyMin: any = params.get("qtyMin");
    const party: any = params.get("partySize");
    const id: any = params.get("locationId");
    const startDate: any = params.get("startDate");
    const endDate: any = params.get("endDate");
    const mode: any = params.get("mode");
    const order: any = params.get("orderNo");
    const displayId: any = params.get("displayId");
    const receiptNo: any = params.get("receiptNo");
    const totalAmountMin: any = params.get("totalAmountMin");
    const totalAmountMax: any = params.get("totalAmountMax");
    const paymentType: any = params.get("paymentType");
    const channel: any = params.get("channel");
    const platform: any = params.get("platform");
    const user: any = params.get("user");
    const basedOnShift: any = params.get("basedOnShift");
    const amountMin: any = params.get("amountMin");
    const amountMax: any = params.get("amountMax");
    const vatPercent: any = params.get("vatPercent");
    const payInOrOut: any = params.get("payInOrOut");
    const terminal: any = params.get("terminal");
    const type: any = params.get("type");
    const orderStatus: any = params.get("orderStatus");
    const orderTotalMin: any = params.get("orderTotalMin");
    const orderTotalMax: any = params.get("orderTotalMax");
    const deliveryType: any = params.get("deliveryType");
    const dueAndOrderTime: any = params.get("dueAndOrderTime");
    const isAppEmbedded: any = params.get("isAppEmbedded");
    /* When there is no location id in the query parameters, 
    the corresponding location and this month are entered for the query parameters. */
    if (_.isEmpty(id) && isGetLocationInfo) {
      const filteredCurrentLocation = locationSelectorList.filter(
        (itm: any) => itm.id === match.params.locationId,
      );

      setLocationSelectedList(filteredCurrentLocation);
      handleAllSelectedList("location", filteredCurrentLocation);
      if (payInOut) {
        handleChangeDateRange({
          selection: {
            startDate: new Date(
              moment(new Date()).startOf("month").format("YYYY-MM-DD"),
            ),
            key: "selection",
            endDate: new Date(moment(new Date()).format("YYYY-MM-DD")),
          },
        });
      }
    } else if (!_.isEmpty(params.toString()) && isGetLocationInfo) {
      if (discountValueMin !== null) {
        setDiscountValueMin(discountValueMin);
        setFilterDiscountValueMin(discountValueMin);
      }

      if (discountValueMax !== null) {
        setDiscountValueMax(discountValueMax);
        setFilterDiscountValueMax(discountValueMax);
      }
      if (qtyMin !== null) {
        setQtyMin(qtyMin);
        setFilterQtyMin(qtyMin);
      }
      if (qtyMax !== null) {
        setQtyMax(qtyMax);
        setFilterQtyMax(qtyMax);
      }
      if (amountMin !== null) {
        setAmountMin(amountMin);
        setFilterAmountMin(amountMin);
      }
      if (orderTotalMin !== null) {
        setOrderTotalMin(orderTotalMin);
        setFilterOrderTotalMin(orderTotalMin);
      }
      if (amountMax !== null) {
        setAmountMax(amountMax);
        setFilterAmountMax(amountMax);
      }
      if (orderTotalMax !== null) {
        setOrderTotalMax(orderTotalMax);
        setFilterOrderTotalMax(orderTotalMax);
      }
      if (order !== null) {
        setOrderNo(order);
        setFilterOrderNo(order);
      }
      if (displayId !== null) {
        setQueOrderNo(displayId);
        setFilterQueOrderNo(displayId);
      }
      if (receiptNo !== null) {
        setReceiptNo(receiptNo);
        setFilterReceiptNo(receiptNo);
      }
      if (totalAmountMin !== null) {
        setTotalAmountMin(totalAmountMin);
        setFilterTotalAmountMin(totalAmountMin);
      }
      if (totalAmountMax !== null) {
        setTotalAmountMax(totalAmountMax);
        setFilterTotalAmountMax(totalAmountMax);
      }
      if (user !== null) {
        const userList: any = [...user.split(",")];
        setUserInitialList(userList);
      }
      if (id !== null) {
        const idList = [...id.split(",")];
        const filteredLocationArray = getFilterListFromArrayObjectAndArray(
          locationSelectorList,
          idList,
        );
        if (filteredLocationArray.length === 1) {
        }
        setLocationSelectedList(filteredLocationArray);
        handleAllSelectedList("location", filteredLocationArray);
      }
      if (payInOrOut !== null) {
        const payInOrOutList = [...payInOrOut.split(",")];
        const filteredPartySizeArray = getFilterListFromArrayObjectAndArray(
          payInOutSelectorList,
          payInOrOutList,
        );
        setPayInOutSelectedList(filteredPartySizeArray);
        handleAllSelectedList("payInOut", filteredPartySizeArray);
      }
      if (type !== null) {
        const typeList = [...type.split(",")];
        const filteredPartySizeArray = getFilterListFromArrayObjectAndArray(
          typeSelectorList,
          typeList,
        );
        setAllTypeSelectedList(filteredPartySizeArray);
        handleAllSelectedList("type", filteredPartySizeArray);
      }
      if (party !== null) {
        const partyList = [...party.split(",")];
        const filteredPartySizeArray = getFilterListFromArrayObjectAndArray(
          partySizeSelectorList,
          partyList,
        );
        setPartySizeSelectedList(filteredPartySizeArray);
        handleAllSelectedList("partySize", filteredPartySizeArray);
      }

      if (terminal !== null) {
        const terminalList = [...terminal.split(",")];
        const filteredTerminalArray = getFilterListFromArrayObjectAndArray(
          terminalSelectorList,
          terminalList,
        );
        setTerminalSelectedList(filteredTerminalArray);
        handleAllSelectedList("terminal", filteredTerminalArray);
      }

      if (mode !== null) {
        const modeList = [...mode.split(",")];
        const filteredLocationArray = getFilterListFromArrayObjectAndArray(
          defaultModeSelectorList,
          modeList,
        );
        handleAllSelectedList("mode", filteredLocationArray);
      }
      if (vatPercent !== null) {
        const vatPercentList = [...vatPercent.split(",")];
        const filteredLocationArray = getFilterListFromArrayObjectAndArray(
          vatPercentSelectorList,
          vatPercentList,
        );
        handleAllSelectedList("vatPercent", filteredLocationArray);
      }

      if (paymentType !== null) {
        let paymentTypeList: any = [...paymentType.split(",")];
        const filteredTypeArray = getFilterListFromArrayObjectAndArray(
          isOrder || isOrderReport
            ? eatprestoPaymentTypeSelectorList
            : paymentTypeSelectorList,
          paymentTypeList,
        );

        handleAllSelectedList("paymentType", filteredTypeArray);
      }
      if (orderStatus !== null) {
        let orderStatusList: any = [...orderStatus.split(",")];
        const filteredTypeArray = getFilterListFromArrayObjectAndArray(
          orderStatusSelectorList,
          orderStatusList,
        );
        handleAllSelectedList("orderStatus", filteredTypeArray);
      }
      if (deliveryType !== null) {
        let orderStatusList: any = [...deliveryType.split(",")];
        const filteredTypeArray = getFilterListFromArrayObjectAndArray(
          orderTypeSelectorList,
          orderStatusList,
        );
        handleAllSelectedList("deliveryType", filteredTypeArray);
      }
      if (channel !== null) {
        let channelList: any = [...channel.split(",")];
        const filteredChannelArray = getFilterListFromArrayObjectAndArray(
          channelSelectorList,
          channelList,
        );
        handleAllSelectedList("channel", filteredChannelArray);
      }
      if (platform !== null) {
        let channelList: any = [...platform.split(",")];
        const filteredChannelArray = getFilterListFromArrayObjectAndArray(
          platformSelectorList,
          channelList,
        );
        handleAllSelectedList("platform", filteredChannelArray);
      }
      if (startDate !== null) {
        handleChangeDateRange({
          selection: {
            startDate: new Date(startDate),
            key: "selection",
            endDate: new Date(endDate),
          },
        });

        if (isChart) {
          /* This will only work if the query parameters contain this month. */
          if (
            convertDateToMoment(startDate) ===
              getStartDateDateMonthYear(new Date(), "month") &&
            convertDateToMoment(endDate) ===
              getEndDateDateMonthYear(new Date(), "month")
          ) {
            handleChangePreviousDateRange({
              selection: {
                startDate: new Date(
                  moment(startDate)
                    .subtract(1, "months")
                    .startOf("month")
                    .format("YYYY-MM-DD"),
                ),
                key: "selection",
                endDate: new Date(
                  moment(startDate)
                    .subtract(1, "months")
                    .endOf("month")
                    .format("YYYY-MM-DD"),
                ),
              },
            });
          } else if (
            startDate ===
              getStartDateDateMonthYear(
                new Date(
                  moment(startDate).startOf("month").format("YYYY-MM-DD"),
                ),
                "month",
              ) &&
            endDate ===
              getEndDateDateMonthYear(
                new Date(moment(startDate).endOf("month").format("YYYY-MM-DD")),
                "month",
              )
          ) {
            handleChangePreviousDateRange({
              selection: {
                startDate: new Date(
                  moment(startDate)
                    .subtract(1, "months")
                    .startOf("month")
                    .format("YYYY-MM-DD"),
                ),
                key: "selection",
                endDate: new Date(
                  moment(startDate)
                    .subtract(1, "months")
                    .endOf("month")
                    .format("YYYY-MM-DD"),
                ),
              },
            });
          } else if (
            /* This will only work if the query parameters contain this year. */
            convertDateToMoment(startDate) ===
              getStartDateDateMonthYear(new Date(), "year") &&
            convertDateToMoment(endDate) ===
              getEndDateDateMonthYear(new Date(), "year")
          ) {
            handleChangePreviousDateRange({
              selection: {
                startDate: new Date(
                  moment()
                    .subtract(1, "y")
                    .startOf("year")
                    .format("YYYY-MM-DD"),
                ),
                key: "selection",
                endDate: new Date(
                  moment().subtract(1, "y").endOf("year").format("YYYY-MM-DD"),
                ),
              },
            });
          } else if (
            /* This will only work if the query parameters contain date. */
            convertDateToMoment(startDate) === convertDateToMoment(endDate)
          ) {
            handleChangePreviousDateRangeDefault({
              selection: {
                startDate: new Date(
                  moment(startDate).subtract(1, "d").format("YYYY-MM-DD"),
                ),
                key: "selection",
                endDate: new Date(
                  moment(startDate).subtract(1, "d").format("YYYY-MM-DD"),
                ),
              },
            });
          } else {
            /* This works when the current start date and current end date are unequal and the previous start date and 
              previous end date are equal. Here, The previous date range is changed according to the current date range. */
            const diff: number =
              moment(endDate).diff(moment(startDate), "days") + 1;
            handleChangePreviousDateRange({
              selection: {
                startDate: new Date(
                  moment(startDate).subtract(diff, "d").format("YYYY-MM-DD"),
                ),
                key: "selection",
                endDate: new Date(
                  moment(startDate).subtract(1, "d").format("YYYY-MM-DD"),
                ),
              },
            });
          }
        }
      }
      if (basedOnShift !== null) {
        const diff: number = moment(endDate).diff(moment(startDate), "days");
        if (diff === 0 && isChart && !isDailySale) {
          setIsBasedOnShift(false);
          setIsBasedOnShiftButtonDisabled(true);
        } else {
          setIsBasedOnShiftButtonDisabled(false);
          if (basedOnShift === "true") {
            setIsBasedOnShift(true);
          } else {
            setIsBasedOnShift(false);
          }
        }
      }
      if (dueAndOrderTime !== null) {
        if (dueAndOrderTime === "true") {
          setOrderAndDueTimeSwitch(true);
        } else {
          setOrderAndDueTimeSwitch(false);
        }
      }

      if (isAppEmbedded !== null) {
        if (isAppEmbedded === "true") {
          setIsAppEmbedded(true);
        } else {
          setIsAppEmbedded(false);
        }
      }
    }
  }, [isGetLocationInfo]);

  /* Remove empty query params */
  const handleRemoveEmptyQuery = (queryParam: any) => {
    const params = new URLSearchParams(queryParam);
    let keysForDel: any = [];
    params.forEach((value, key) => {
      if (value === "" || value === null) {
        keysForDel.push(key);
      }
    });
    keysForDel.forEach((key: any) => {
      params.delete(key);
    });
    return params;
  };
  const isAppEmbeddedd: any = params.get("isAppEmbedded");

  /* Update query params when change the state */
  const handleUpdateQueryParams = () => {
    const queryParam = {
      discountValueMin: filterDiscountValueMin,
      discountValueMax: filterDiscountValueMax,
      qtyMin: filterQtyMin,
      qtyMax: filterQtyMax,
      locationId: locationFilterList.toString(),
      startDate: startDate,
      endDate: endDate,
      mode: typeFilterList.toString(),
      channel: channelFilterList.toString(),
      platform: platformFilterList.toString(),
      partySize: partySizeFilterList.toString(),
      terminal: terminalFilterList.toString(),
      paymentType: paymentTypeFilterList.toString(),
      user: userFilterList.toString(),
      orderNo: orderNo,
      displayId: queOrderNo,
      receiptNo: receiptNo,
      totalAmountMin: filterTotalAmountMin,
      totalAmountMax: filterTotalAmountMax,
      basedOnShift: isRemoveBasedOnShift ? "" : isBasedOnShift,
      amountMin: filterAmountMin,
      amountMax: filterAmountMax,
      vatPercent: vatPercentFilterList.toString(),
      payInOrOut: payInOrOutFilterList.toString(),
      type: alTypeFilterList.toString(),
      orderStatus: orderStatusFilterList.toString(),
      orderTotalMin: filterOrderTotalMin.toString(),
      orderTotalMax: filterOrderTotalMax.toString(),
      deliveryType: deliveryTypeFilterList.toString(),
      dueAndOrderTime: isRemoveDueAndOrderTime ? "" : orderAndDueTimeSwitch,
      isAppEmbedded: isAppEmbeddedd ? isAppEmbeddedd : "",
    };
    const params = handleRemoveEmptyQuery(queryParam);
    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  /* Change the state after changing a query params. */
  const handleQueryParm = () => {
    const locationId: any = params.get("locationId");
    const startDate: any = params.get("startDate");
    const endDate: any = params.get("endDate");

    let arrLocation: any = [];
    if (!_.isEmpty(locationSelectorList) && !_.isEmpty(locationId)) {
      let idList = [...locationId.split(",")];
      arrLocation = getLabelListFromArrayObjectAndArray(
        locationSelectorList,
        idList,
      );
    }

    let uniqueLocation = [...new Set(arrLocation)];

    const filterDetails = handleFilterDataDetails(
      uniqueLocation,
      startDate,
      endDate,
    );
    setFilterDetails(filterDetails);
  };

  useEffect(() => {
    handleQueryParm();
  }, [params]);

  useEffect(() => {
    if (params?.size === 0) {
      handleFilterRequest();
    }
    handleQueryParm();
  }, []);

  const handleFilterRequest = () => {
    // Check if location info needs to be fetched.
    if (isGetLocationInfo) {
      handleUpdateQueryParams();
    }
    if (isOrderReport) {
    } else {
    }
    // Perform filtering and obtain filter count and query.
    let { filterCount, filterQuery } = isOrderReport
      ? handleFilterDataEatprestoOrder(
          locationFilterList,
          startDate,
          endDate,
          channelFilterList,
          paymentTypeFilterList,
          deliveryTypeFilterList,
        )
      : handleFilterData(
          filterDiscountValueMin,
          filterDiscountValueMax,
          filterQtyMin,
          filterQtyMax,
          locationFilterList,
          filterReceiptNo,
          queOrderNo,
          startDate,
          endDate,
          filterTotalAmountMin,
          filterTotalAmountMax,
          orderNo,
          typeFilterList,
          channelFilterList,
          partySizeFilterList,
          paymentTypeFilterList,
          userFilterList,
          platformFilterList,
          filterAmountMin,
          filterAmountMax,
          vatPercentFilterList,
          payInOrOutFilterList,
          alTypeFilterList,
          isSale,
          isSaleItem,
          isShift,
          isOrder,
          orderStatusFilterList,
          filterOrderTotalMin,
          filterOrderTotalMax,
          deliveryTypeFilterList,
          orderAndDueTimeSwitch,
          terminalFilterList,
        );

    let previousFilterQuery: any;

    // If it's a chart view, perform filtering for the previous date range.
    if (isChart) {
      previousFilterQuery = handleFilterData(
        filterDiscountValueMin,
        filterDiscountValueMax,
        filterQtyMin,
        filterQtyMax,
        locationFilterList,
        filterReceiptNo,
        queOrderNo,
        previousStartDate,
        previousEndDate,
        filterTotalAmountMin,
        filterTotalAmountMax,
        orderNo,
        typeFilterList,
        channelFilterList,
        partySizeFilterList,
        paymentTypeFilterList,
        userFilterList,
        platformFilterList,
        filterAmountMin,
        filterAmountMax,
        vatPercentFilterList,
        payInOrOutFilterList,
        alTypeFilterList,
        terminalFilterList,
      );
    }
    // Adjust filter count if certain conditions are met.
    if (isGetLocationInfo) {
      // Adjust filter count based on specific filters.
      if (
        !isOrderReport &&
        filterQuery.includes("totalAmount>") &&
        filterQuery.includes("totalAmount<")
      ) {
        filterCount -= 1;
      }
      if (
        !isOrderReport &&
        filterQuery.includes("discountValue>") &&
        filterQuery.includes("discountValue<")
      ) {
        filterCount -= 1;
      }
      if (
        !isOrderReport &&
        filterQuery.includes("amount>") &&
        filterQuery.includes("amount<")
      ) {
        filterCount -= 1;
      }
      if (
        !isOrderReport &&
        filterQuery.includes("qty<=") &&
        filterQuery.includes("qty>=")
      ) {
        filterCount -= 1;
      }
      if (
        !isOrderReport &&
        filterQuery.includes("discount>") &&
        filterQuery.includes("discount<")
      ) {
        filterCount -= 1;
      }
      if (
        !isOrderReport &&
        filterQuery.includes("paymentAmount>=") &&
        filterQuery.includes("paymentAmount<=")
      ) {
        filterCount -= 1;
      }

      // Update the filter count.
      setCountOfFilters(filterCount - 1);

      // Fetch filter data based on the current filter query.
      getFilterData(filterQuery, isBasedOnShift);

      // If it's a chart view, adjust filter count and fetch data for the previous filter query.
      if (isChart) {
        let count = previousFilterQuery.filterCount;
        if (
          filterQuery.includes("totalAmount>") &&
          filterQuery.includes("totalAmount<")
        ) {
          count -= 1;
        }
        if (
          filterQuery.includes("discountValue>") &&
          filterQuery.includes("discountValue<")
        ) {
          count -= 1;
        }
        if (
          filterQuery.includes("amount>") &&
          filterQuery.includes("amount<")
        ) {
          filterCount -= 1;
        }
        if (filterQuery.includes("qty<=") && filterQuery.includes("qty>=")) {
          filterCount -= 1;
        }
        if (
          filterQuery.includes("discount>") &&
          filterQuery.includes("discount<")
        ) {
          filterCount -= 1;
        }
        if (
          filterQuery.includes("paymentAmount>=") &&
          filterQuery.includes("paymentAmount<=")
        ) {
          filterCount -= 1;
        }

        setCountOfFilters(count - 1);
        getPreviousFilterInfo(previousFilterQuery.filterQuery, isBasedOnShift);
      }
    }
  };

  /* The values ​​to be filtered are set as query parameters and provided that data to the API. */
  useEffect(() => {
    handleFilterRequest();
  }, [
    filterDiscountValueMin,
    filterDiscountValueMax,
    filterQtyMin,
    filterQtyMax,
    locationFilterList,
    filterReceiptNo,
    filterQueOrderNo,
    startDate,
    endDate,
    filterTotalAmountMin,
    filterTotalAmountMax,
    filterOrderNo,
    typeFilterList,
    channelFilterList,
    platformFilterList,
    partySizeFilterList,
    paymentTypeFilterList,
    userFilterList,
    isBasedOnShift,
    filterAmountMin,
    filterAmountMax,
    vatPercentFilterList,
    previousStartDate,
    previousEndDate,
    payInOrOutFilterList,
    alTypeFilterList,
    orderStatusFilterList,
    filterOrderTotalMin,
    filterOrderTotalMax,
    deliveryTypeFilterList,
    orderAndDueTimeSwitch,
    terminalFilterList,
  ]);

  /* This useEffect will be executed 2 minutes after typing the last letter in the text field. */
  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (!_.isEqual(amountMin, filterAmountMin)) {
        setFilterAmountMin(amountMin);
        if (parseFloat(amountMin) > parseFloat(amountMax)) {
          setFilterAmountMax("");
          setAmountMax("");
        }
      }
      if (!_.isEqual(amountMax, filterAmountMax)) {
        setFilterAmountMax(amountMax);
        if (parseFloat(amountMin) > parseFloat(amountMax)) {
          setAmountMin("");
          setFilterAmountMin("");
        }
      }

      if (!_.isEqual(orderTotalMin, filterOrderTotalMin)) {
        setFilterOrderTotalMin(orderTotalMin);
        if (parseFloat(orderTotalMin) > parseFloat(orderTotalMax)) {
          setFilterOrderTotalMax("");
          setAmountMax("");
        }
      }
      if (!_.isEqual(orderTotalMax, filterOrderTotalMax)) {
        setFilterOrderTotalMax(orderTotalMax);
        if (parseFloat(orderTotalMin) > parseFloat(orderTotalMax)) {
          setOrderTotalMin("");
          setFilterOrderTotalMin("");
        }
      }

      if (!_.isEqual(qtyMin, filterQtyMin)) {
        setFilterQtyMin(qtyMin);
        if (parseFloat(qtyMin) > parseFloat(qtyMax)) {
          setFilterQtyMax("");
          setQtyMax("");
        }
      }
      if (!_.isEqual(qtyMax, filterQtyMax)) {
        setFilterQtyMax(qtyMax);
        if (parseFloat(qtyMin) > parseFloat(qtyMax)) {
          setQtyMin("");
          setFilterQtyMin("");
        }
      }

      if (!_.isEqual(discountValueMin, filterDiscountValueMin)) {
        setFilterDiscountValueMin(discountValueMin);
        if (parseFloat(discountValueMin) > parseFloat(discountValueMax)) {
          setFilterDiscountValueMax("");
          setDiscountValueMax("");
        }
      }
      if (!_.isEqual(discountValueMax, filterDiscountValueMax)) {
        setFilterDiscountValueMax(discountValueMax);
        if (parseFloat(discountValueMin) > parseFloat(discountValueMax)) {
          setDiscountValueMin("");
          setFilterDiscountValueMin("");
        }
      }

      if (!_.isEqual(totalAmountMin, filterTotalAmountMin)) {
        setFilterTotalAmountMin(totalAmountMin);
        if (parseFloat(totalAmountMin) > parseFloat(totalAmountMax)) {
          setFilterTotalAmountMax("");
          setTotalAmountMax("");
        }
      }
      if (!_.isEqual(totalAmountMax, filterTotalAmountMax)) {
        setFilterTotalAmountMax(totalAmountMax);
        if (parseFloat(totalAmountMin) > parseFloat(totalAmountMax)) {
          setTotalAmountMin("");
          setFilterTotalAmountMin("");
        }
      }

      if (receiptNo !== filterReceiptNo) {
        setFilterReceiptNo(receiptNo);
      }
      if (orderNo !== filterOrderNo) {
        setFilterOrderNo(orderNo);
      }
      if (queOrderNo !== filterQueOrderNo) {
        setFilterQueOrderNo(queOrderNo);
      }
    }, 2000);
    return () => clearTimeout(timeOutId);
  }, [
    amountMin,
    amountMax,
    qtyMin,
    qtyMax,
    discountValueMin,
    discountValueMax,
    totalAmountMin,
    totalAmountMax,
    receiptNo,
    queOrderNo,
    orderNo,
    orderTotalMin,
    orderTotalMax,
  ]);

  /* Change the based on shift */
  const handleBasedOnShift = (e: any) => {
    const { checked } = e.target;
    setIsBasedOnShift(checked);
  };

  /* Open and close filter card */
  const handleChangeFilterCard = () => {
    setOpenFilterCard(!openFilterCard);
  };

  /**
   * Handles the change of the order and due time switch.
   *
   * @param {boolean} newValue - The new value of the switch.
   */
  const handleChangeOrderAndDueTimeSwitch = () => {
    setOrderAndDueTimeSwitch(!orderAndDueTimeSwitch);
  };
  const isAppEmbeddedQuery = queryString.parse(
    window.location.search,
  ).isAppEmbedded;

  const classes = useStyles();

  return (
    <>
      {isAppEmbeddedQuery !== "true" && (
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            marginTop: "16px",
          }}
        >
          <Badge
            badgeContent={countOfFilters}
            color="secondary"
            className={classes.badgeFontColor}
          >
            <IconButton
              className={classes.iconButton}
              onClick={handleChangeFilterCard}
            >
              <Typography style={{ display: "flex", alignItems: "center" }}>
                <TuneIcon fontSize="small" />
              </Typography>
              <Typography style={{ marginLeft: "8px", letterSpacing: "1px" }}>
                Filters
              </Typography>
            </IconButton>
          </Badge>
        </div>
      )}
      {openFilterCard && (
        <FilterCard
          setDiscountValueMin={(e: any) => setDiscountValueMin(e)}
          discountValueMin={discountValueMin}
          setDiscountValueMax={(e: any) => setDiscountValueMax(e)}
          setReceiptNo={(e: any) => setReceiptNo(e)}
          receiptNo={receiptNo}
          discountValueMax={discountValueMax}
          locationSelectorList={locationSelectorList}
          handleAllSelectedList={handleAllSelectedList}
          getLocationSelectorTypingList={getLocationSelectorTypingList}
          searchLocationName={searchLocationName}
          setSearchLocationName={(e: any) => setSearchLocationName(e)}
          locationSelectedList={locationSelectedList}
          setQueOrderNo={setQueOrderNo}
          queOrderNo={queOrderNo}
          handleChangeDateRange={handleChangeDateRange}
          dateSelectionRange={dateSelectionRange}
          startDate={startDate}
          endDate={endDate}
          totalAmountMin={totalAmountMin}
          totalAmountMax={totalAmountMax}
          setTotalAmountMin={setTotalAmountMin}
          setTotalAmountMax={setTotalAmountMax}
          orderNo={orderNo}
          setOrderNo={setOrderNo}
          typeSelectedList={typeSelectedList}
          channelSelectedList={channelSelectedList}
          platformSelectedList={platformSelectedList}
          partySizeSelectedList={partySizeSelectedList}
          paymentTypeSelectedList={paymentTypeSelectedList}
          userSelectedList={userSelectedList}
          userSelectorList={userSelectorList}
          setFilterOrderNo={setFilterOrderNo}
          setFilterQueOrderNo={setFilterQueOrderNo}
          setFilterReceiptNo={setFilterReceiptNo}
          setFilterTotalAmountMin={setFilterTotalAmountMin}
          setFilterTotalAmountMax={setFilterTotalAmountMax}
          setFilterDiscountValueMin={setFilterDiscountValueMin}
          setFilterDiscountValueMax={setFilterDiscountValueMax}
          isBasedOnShift={isBasedOnShift}
          handleBasedOnShift={handleBasedOnShift}
          availableFilter={availableFilter}
          qtyMin={qtyMin}
          setQtyMin={setQtyMin}
          qtyMax={qtyMax}
          setQtyMax={setQtyMax}
          setFilterQtyMin={setFilterQtyMin}
          setFilterQtyMax={setFilterQtyMax}
          amountMin={amountMin}
          amountMax={amountMax}
          setAmountMin={setAmountMin}
          setAmountMax={setAmountMax}
          setFilterAmountMin={setFilterAmountMin}
          setFilterAmountMax={setFilterAmountMax}
          vatPercentSelectedList={vatPercentSelectedList}
          handleChangePreviousDateRange={handleChangePreviousDateRange}
          previousDateSelectionRange={previousDateSelectionRange}
          previousStartDate={previousStartDate}
          previousEndDate={previousEndDate}
          isChart={isChart}
          payInOutSelectedList={payInOutSelectedList}
          allTypeSelectedList={allTypeSelectedList}
          isBasedOnShiftButtonDisabled={isBasedOnShiftButtonDisabled}
          orderStatusSelectedList={orderStatusSelectedList}
          orderTotalMin={orderTotalMin}
          setOrderTotalMin={setOrderTotalMin}
          orderTotalMax={orderTotalMax}
          setOrderTotalMax={setOrderTotalMax}
          setFilterOrderTotalMax={setFilterOrderTotalMax}
          setFilterOrderTotalMin={setFilterOrderTotalMin}
          isOrder={isOrder}
          deliveryTpeSelectedList={deliveryTpeSelectedList}
          handleChangeOrderAndDueTimeSwitch={handleChangeOrderAndDueTimeSwitch}
          orderAndDueTimeSwitch={orderAndDueTimeSwitch}
          isOrderReport={isOrderReport}
          terminalSelectorList={terminalSelectorList}
          terminalSelectedList={terminalSelectedList}
          isActiveUserSwitch={isActiveUserSwitch}
          handleActivateSwitch={handleActivateSwitch}
        />
      )}
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default Filter;

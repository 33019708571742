import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useRouteMatch } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import "jspdf-autotable";

import WithLoading from "../../../../utils/WithLoading";
import CardCommon from "../../../../components/card/CardCommon";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import RewardsAddModal from "./RewardsAddModal";
import {
  createNewRewardsInfo,
  updateRewardsInfo,
} from "../../../../services/customerApp/customerDetails";
import { saveLogs } from "../../../../utils/firebase/logs";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
} from "../../../../utils/consts";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";

export interface CustomerNodeProps {
  rewardNode: any;
  isLoading: any;
  isOwner: any;
  programId: any;
}

/**
 * CustomerNode Component
 *
 * This component is responsible for rendering the customer node table.
 * It displays customer information in a table format and provides options for sorting and exporting data.
 */
const CustomerNode: React.FunctionComponent<CustomerNodeProps> = ({
  rewardNode,
  isOwner,
  programId,
}) => {
  const [rewardNodeList, setRewardNodeList] = useState<any>([]);
  const [editableRewardDetails, setEditableRewardDetails] = useState<any>([]);
  const [editableRewardId, setEditableRewardId] = useState<any>([]);
  const [isOpenRewardsAddModal, setIsOpenRewardsAddModal] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [rewardDetails, setRewardDetails] = useState<any>([]);
  const [isPointsReset, setIsPointsReset] = useState(false);
  const [error, setError] = useState("");
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [selectedStrategyNode, setSelectedStrategyNode] = useState<any>([]);
  const [isEditDetails, setIsEditDetails] = useState(false);
  const [success, setSuccess] = useState("");
  const [level, setLevel] = useState(1);
  const [isEditPoint, setIsEditPoint] = useState(false);
  const [isPointsResetFirstObj, setIsPointsResetFirstObj] = useState(false);

  const match: any = useRouteMatch();

  useEffect(() => {
    if (rewardNode) {
      setRewardNodeList(rewardNode);
      console.log("ssssssssssssssssdfsdf", Object.values(rewardNode));
      const isPointsReset: any = Object.values(rewardNode)[0];
      setIsPointsResetFirstObj(isPointsReset?.is_points_reset || false);
    }
  }, [rewardNode]);

  const handleCreateRewards = async (formData: any) => {
    setIsLoadingButton(true);
    try {
      const res = await createNewRewardsInfo(match.params.locationId, formData);
      let summary = [];
      summary.push(`Created Title is ${formData.title}`);

      formData.description &&
        summary.push(`Created Description is ${formData.description}`);

      formData.img && summary.push(`Created Image is ${formData.img}`);

      formData.units_per_cash &&
        summary.push(`Created Units Per Cash is ${formData.units_per_cash}`);

      const summaryString = summary.join("\\n");
      saveLogs(
        {
          message: "Rewards Information Added",
          summary: summaryString,
          type: "info",
          code: "create",
          metadata: { changedBy: "" },
        },
        "customer",
        match.params.locationId,
      );

      const { data } = res;
      const response = data.data;
      const cloneNodes = _.cloneDeep(rewardNodeList);
      cloneNodes[Object.keys(response)[0]] = Object.values(response)[0];
      setRewardNodeList(cloneNodes);

      // Set the success message to indicate that the strategy was created successfully.
      setSuccess(SUCCESSFULLY_CREATED);
      setIsOpenRewardsAddModal(false);
      setIsLoadingButton(false);
    } catch (error) {
      // If an error occurs during the creation process, handle the error by setting isLoadingButton to false and
      // displaying an error message.
      setIsLoadingButton(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleOpenAddRewardsModal = () => {
    setIsOpenRewardsAddModal(true);
    setEditableRewardDetails([]);
    setEditableRewardId("");
  };

  const handleGetIndex = (unitsToRedeemValue: any, rewardId?: any) => {
    let index = 0;

    const unitsArray = Object.entries(rewardNodeList)
      .map(([key, item]: any) => {
        if (item && rewardId !== key) {
          return parseInt(item.units_to_redeem, 10);
        }
        return NaN;
      })
      .filter((value) => !isNaN(value));
    if (unitsArray.length > 0) {
      const sortedUnits = unitsArray.sort((a, b) => a - b);
      sortedUnits.map((data: any) => {
        if (
          parseFloat(unitsToRedeemValue.toString()) >
          parseFloat(data.toString())
        ) {
          index += 1;
        }
      });
    }

    return index;
  };

  const handleUpdateNewRewardsEdits = async (
    editableRewardDetails: any,
    selectedWrapperIdList: any,
    isEdit: any,
    rewardId: any,
  ) => {
    const index = handleGetIndex(
      editableRewardDetails.units_to_redeem,
      rewardId,
    );
    const rewardsInfo = {
      description: editableRewardDetails.description,
      img: editableRewardDetails.img,
      product_id: programId,
      title: editableRewardDetails.title,
      type: "product",
      is_points_reset: isPointsReset,
      units_to_redeem: editableRewardDetails.units_to_redeem,
      products: selectedWrapperIdList,
      listIndex: index,
    };

    try {
      const res = await updateRewardsInfo(
        match.params.locationId,
        rewardId,
        rewardsInfo,
      );

      let summary = [];
      summary.push(`Created Title is ${rewardDetails.title}`);

      rewardDetails.description &&
        summary.push(`Created Description is ${rewardDetails.description}`);

      rewardDetails.img &&
        summary.push(`Created Image is ${rewardDetails.img}`);

      rewardDetails.units_per_cash &&
        summary.push(
          `Created Units Per Cash is ${rewardDetails.units_per_cash}`,
        );

      rewardDetails &&
        summary.push(`Created Product details is ${rewardDetails}`);

      const summaryString = summary.join("\\n");
      saveLogs(
        {
          message: "Rewards Information Added",
          summary: summaryString,
          type: "info",
          code: "create",
          metadata: { changedBy: "" },
        },
        "customer",
        match.params.locationId,
      );

      const { data } = res;

      const response = data.data;
      const cloneNodes = _.cloneDeep(rewardNodeList);

      cloneNodes[rewardId] = response;
      setRewardNodeList(cloneNodes);
      // Set the success message to indicate that the strategy was created successfully.
      setSuccess(SUCCESSFULLY_CREATED);
      setIsOpenRewardsAddModal(false);
      setIsLoadingButton(false);
      setLevel(1);
      setIsEditPoint(false);
    } catch (error) {
      // If an error occurs during the creation process, handle the error by setting isLoadingButton to false and
      // displaying an error message.
      setIsLoadingButton(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleUpdateNewRewardsEdit = async (formData: any, rewardId: any) => {
    const index = handleGetIndex(formData.units_to_redeem, rewardId);
    const rewardsInfo = {
      description: formData.description,
      img: formData.img,
      product_id: formData.product_id,
      title: formData.title,
      type: "product",
      is_points_reset: isPointsReset,
      units_to_redeem: formData.units_to_redeem,
      products: formData.products,
      listIndex: index,
    };

    try {
      const res = await updateRewardsInfo(
        match.params.locationId,
        rewardId,
        rewardsInfo,
      );

      let summary = [];
      summary.push(`Created Title is ${rewardDetails.title}`);

      rewardDetails.description &&
        summary.push(`Created Description is ${rewardDetails.description}`);

      rewardDetails.img &&
        summary.push(`Created Image is ${rewardDetails.img}`);

      rewardDetails.units_per_cash &&
        summary.push(
          `Created Units Per Cash is ${rewardDetails.units_per_cash}`,
        );

      rewardDetails &&
        summary.push(`Created Product details is ${rewardDetails}`);

      const summaryString = summary.join("\\n");
      saveLogs(
        {
          message: "Rewards Information Added",
          summary: summaryString,
          type: "info",
          code: "create",
          metadata: { changedBy: "" },
        },
        "customer",
        match.params.locationId,
      );

      const { data } = res;

      const response = data.data;
      const cloneNodes = _.cloneDeep(rewardNodeList);

      cloneNodes[rewardId] = response;
      const sortedEntries = Object.entries(cloneNodes).sort(
        ([, a]: any, [, b]: any) => a.units_to_redeem - b.units_to_redeem,
      );

      // Convert the sorted array back into an object
      const sortedNode = Object.fromEntries(sortedEntries);

      setRewardNodeList(sortedNode);
      // Set the success message to indicate that the strategy was created successfully.
      setSuccess(SUCCESSFULLY_CREATED);
      setIsOpenRewardsAddModal(false);
      setIsLoadingButton(false);
      setIsEditDetails(false);
    } catch (error) {
      // If an error occurs during the creation process, handle the error by setting isLoadingButton to false and
      // displaying an error message.
      setIsLoadingButton(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleUpdateNewRewards = async (formData: any, rewardId: any) => {
    const index = handleGetIndex(rewardDetails.units_to_redeem, rewardId);
    const rewardsInfo = {
      description: rewardDetails.description,
      img: rewardDetails.img,
      product_id: rewardDetails.product_id,
      strategy_id: rewardDetails.strategy_id,
      title: rewardDetails.title,
      type: "product",
      is_points_reset: isPointsReset,
      units_to_redeem: rewardDetails.units_to_redeem,
      products: formData,
      listIndex: index,
    };

    try {
      const res = await updateRewardsInfo(
        match.params.locationId,
        rewardId,
        rewardsInfo,
      );

      let summary = [];
      summary.push(`Created Title is ${rewardDetails.title}`);

      rewardDetails.description &&
        summary.push(`Created Description is ${rewardDetails.description}`);

      rewardDetails.img &&
        summary.push(`Created Image is ${rewardDetails.img}`);

      rewardDetails.units_per_cash &&
        summary.push(
          `Created Units Per Cash is ${rewardDetails.units_per_cash}`,
        );

      rewardDetails &&
        summary.push(`Created Product details is ${rewardDetails}`);

      const summaryString = summary.join("\\n");
      saveLogs(
        {
          message: "Rewards Information Added",
          summary: summaryString,
          type: "info",
          code: "create",
          metadata: { changedBy: "" },
        },
        "customer",
        match.params.locationId,
      );

      const { data } = res;

      const response = data.data;
      const cloneNodes = _.cloneDeep(rewardNodeList);

      cloneNodes[rewardId] = response;
      setRewardNodeList(cloneNodes);
      // Set the success message to indicate that the strategy was created successfully.
      setSuccess(SUCCESSFULLY_CREATED);
      setIsOpenRewardsAddModal(false);
      setIsLoadingButton(false);
    } catch (error) {
      // If an error occurs during the creation process, handle the error by setting isLoadingButton to false and
      // displaying an error message.
      setIsLoadingButton(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleCreateNewRewards = async (formData: any) => {
    const index = handleGetIndex(rewardDetails.units_to_redeem);

    const rewardsInfo = {
      description: rewardDetails.description,
      img: rewardDetails.img,
      product_id: rewardDetails.product_id,
      strategy_id: rewardDetails.strategy_id,
      title: rewardDetails.title,
      type: "product",
      is_points_reset: isPointsReset,
      units_to_redeem: rewardDetails.units_to_redeem,
      products: formData,
      listIndex: index,
    };

    try {
      const res = await createNewRewardsInfo(
        match.params.locationId,
        rewardsInfo,
      );

      let summary = [];
      summary.push(`Created Title is ${rewardDetails.title}`);

      rewardDetails.description &&
        summary.push(`Created Description is ${rewardDetails.description}`);

      rewardDetails.img &&
        summary.push(`Created Image is ${rewardDetails.img}`);

      rewardDetails.units_per_cash &&
        summary.push(
          `Created Units Per Cash is ${rewardDetails.units_per_cash}`,
        );

      rewardDetails &&
        summary.push(`Created Product details is ${rewardDetails}`);

      const summaryString = summary.join("\\n");
      saveLogs(
        {
          message: "Rewards Information Added",
          summary: summaryString,
          type: "info",
          code: "create",
          metadata: { changedBy: "" },
        },
        "customer",
        match.params.locationId,
      );

      const { data } = res;

      const response = data.data;
      const cloneNodes = _.cloneDeep(rewardNodeList);
      cloneNodes[Object.keys(response)[0]] = Object.values(response)[0];

      const sortedEntries = Object.entries(cloneNodes).sort(
        ([, a]: any, [, b]: any) => a.units_to_redeem - b.units_to_redeem,
      );

      // Convert the sorted array back into an object
      const sortedNode = Object.fromEntries(sortedEntries);
      setRewardNodeList(sortedNode);
      // Set the success message to indicate that the strategy was created successfully.
      setSuccess(SUCCESSFULLY_CREATED);
      setIsOpenRewardsAddModal(false);
      setIsLoadingButton(false);
    } catch (error) {
      // If an error occurs during the creation process, handle the error by setting isLoadingButton to false and
      // displaying an error message.
      setIsLoadingButton(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleSubmitRewardsTypePointsEdits = (
    editableRewardDetails: any,
    selectedWrapperIdList: any,
    isEdit: any,
    rewardId: any,
  ) => {
    setIsLoadingButton(true);
    handleUpdateNewRewardsEdits(
      editableRewardDetails,
      selectedWrapperIdList,
      isEdit,
      rewardId,
    );
  };

  const handleSubmitRewardsTypePoints = async (
    formData: any,
    isEdit: any,
    rewardId?: any,
  ) => {
    setIsLoadingButton(true);
    if (isEdit) {
      handleUpdateNewRewards(formData, rewardId);
    } else {
      handleCreateNewRewards(formData);
    }
  };

  const handleSubmitRewardsTypePointsEdit = async (
    formData: any,
    rewardId?: any,
  ) => {
    setIsLoadingButton(true);

    handleUpdateNewRewardsEdit(formData, rewardId);
  };

  const handleEditRewards = async (formData: any, rewardId: any) => {
    setIsLoadingButton(true);
    try {
      const res = await updateRewardsInfo(
        match.params.locationId,
        rewardId,
        formData,
      );

      let summary = [];
      summary.push(`Created Title is ${formData.title}`);

      formData.description &&
        summary.push(`Created Description is ${formData.description}`);

      formData.img && summary.push(`Created Image is ${formData.img}`);

      formData.units_per_cash &&
        summary.push(`Created Units Per Cash is ${formData.units_per_cash}`);

      const summaryString = summary.join("\\n");
      saveLogs(
        {
          message: "Rewards Information Added",
          summary: summaryString,
          type: "info",
          code: "create",
          metadata: { changedBy: "" },
        },
        "customer",
        match.params.locationId,
      );

      const { data } = res;

      const response = data.data;
      const cloneNodes = _.cloneDeep(rewardNodeList);

      cloneNodes[rewardId] = response;

      setRewardNodeList(cloneNodes);
      // Set the success message to indicate that the strategy was created successfully.
      setSuccess(SUCCESSFULLY_CREATED);
      setIsOpenRewardsAddModal(false);
      setIsLoadingButton(false);
    } catch (error) {
      // If an error occurs during the creation process, handle the error by setting isLoadingButton to false and
      // displaying an error message.
      setIsLoadingButton(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleOpenEditRewardModal = (rewardId: any, rewardDetails: any) => {
    setIsOpenRewardsAddModal(true);
    setEditableRewardDetails(rewardDetails);
    setEditableRewardId(rewardId);
    setIsEditDetails(true);
  };

  const rewardType: any = Object.values(rewardNodeList)[0];
  return (
    <>
      <div style={{ marginTop: "24px" }}>
        <Grid container style={{ marginBottom: "20px" }}>
          <Grid
            item
            xs={12}
            // xs={8}
            // lg={9}
            style={{ display: "flex", justifyContent: "end" }}
          >
            <ButtonCommon
              style={{
                fontSize: 11,
                width: "160px",
                height: "40px",
              }}
              disabled={!isOwner || rewardType?.type === "cash"}
              variant="contained"
              color="green"
              onClick={handleOpenAddRewardsModal}
            >
              Add New Reward
            </ButtonCommon>
          </Grid>
        </Grid>
        {!_.isEmpty(rewardNodeList) ? (
          <>
            <Grid container>
              {Object.entries(rewardNodeList).map(
                ([key, value]: any, index: number) => (
                  <>
                    {index === 0 && (
                      <Grid item xs={12}>
                        <CardCommon backgroundColor={"table_header_background"}>
                          <Grid
                            container
                            style={{ minHeight: "40px", placeItems: "center" }}
                          >
                            <Grid
                              item
                              xs={4}
                              style={{
                                display: "flex",
                                justifyContent: "start",
                                paddingLeft: "12px",
                              }}
                            >
                              <Typography>Name</Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography>Description</Typography>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              style={{
                                display: "flex",
                                justifyContent: "end",
                                paddingRight:
                                  value?.type === "product" ? "92px" : "20px",
                              }}
                            >
                              <Typography>Type</Typography>
                            </Grid>
                          </Grid>
                        </CardCommon>
                      </Grid>
                    )}
                    <Grid item xs={12} style={{ marginTop: "4px" }}>
                      <CardCommon backgroundColor={"entity_background"}>
                        <Grid
                          container
                          style={{ minHeight: "40px", placeItems: "center" }}
                        >
                          <Grid
                            item
                            xs={4}
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              paddingLeft: "12px",
                            }}
                          >
                            <Typography>{value.title}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography>{value.description}</Typography>
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              paddingRight:
                                value?.type === "product" ? "92px" : "20px",
                            }}
                          >
                            <Typography>{value.type}</Typography>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              paddingRight: "12px",
                            }}
                          >
                            <ButtonCommon
                              style={{
                                fontSize: 11,
                                marginRight: 12,
                                width: "100px",
                                height: "28px",
                              }}
                              disabled={!isOwner}
                              variant="contained"
                              color="yellow"
                              onClick={() =>
                                handleOpenEditRewardModal(key, value)
                              }
                            >
                              Edit
                            </ButtonCommon>
                            {value?.type === "product" && (
                              <ButtonCommon
                                style={{
                                  fontSize: 11,
                                  minWidth: "92px",
                                  height: "28px",
                                }}
                                disabled={!isOwner}
                                variant="contained"
                                color="yellow"
                                onClick={() => {
                                  handleOpenEditRewardModal(key, value);
                                  setLevel(4);
                                  setIsEditPoint(true);
                                }}
                              >
                                Products
                              </ButtonCommon>
                            )}
                          </Grid>
                        </Grid>
                      </CardCommon>
                    </Grid>
                  </>
                ),
              )}
            </Grid>
          </>
        ) : (
          <Typography variant="h4" style={{ marginTop: "80px" }}>
            Rewards Not Available.
          </Typography>
        )}

        <RewardsAddModal
          isOpen={isOpenRewardsAddModal}
          setIsOpen={setIsOpenRewardsAddModal}
          handleCreateRewards={handleCreateRewards}
          rewardNode={rewardNodeList}
          selectedStrategyNode={selectedStrategyNode}
          isLoadingPage={isLoadingPage}
          handleSubmitRewardsTypePoints={handleSubmitRewardsTypePoints}
          setRewardDetails={setRewardDetails}
          setIsPointsReset={setIsPointsReset}
          isPointsReset={isPointsReset}
          handleEditRewards={handleEditRewards}
          setError={setError}
          isLoadingButton={isLoadingButton}
          isOwner={isOwner}
          programId={programId}
          editableRewardId={editableRewardId}
          editableRewardDetails={editableRewardDetails}
          isEditDetails={isEditDetails}
          handleSubmitRewardsTypePointsEdit={handleSubmitRewardsTypePointsEdit}
          setIsEditDetails={setIsEditDetails}
          level={level}
          setLevel={setLevel}
          isEditPoint={isEditPoint}
          handleSubmitRewardsTypePointsEdits={
            handleSubmitRewardsTypePointsEdits
          }
          setIsEditPoint={setIsEditPoint}
          isPointsResetFirstObj={isPointsResetFirstObj}
        />
      </div>
      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />

      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default WithLoading(CustomerNode);
